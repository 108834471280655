import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import hostNameServer from '../configHostname';
import NewPicklist from '../components/NewPicklist';
import fetch from 'node-fetch';
import { useRefresh } from '../hooks/useRefresh';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useWakeLock } from 'react-screen-wake-lock';

function PicklistPage() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;
  useRefresh();
  const { id } = useParams();
  const [products, setProducts] = useState();
  const [inProducts, setInProducts] = useState();
  const [conceptTitle, setConceptTitle] = useState();
  const [documentId, setDocumentId] = useState();

  async function fetchConcept() {
    const response = await fetch(`${hostNameServer}/api/products/singleconceptpicklist/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      const json = await response.json();
      setDocumentId(json._id);
      setProducts(json);

      json.products.sort(function (a, b) {
        if (a.warehouseLocation === undefined && b.warehouseLocation === undefined) {
          return 0; // Laat de volgorde zoals deze is als beide geen warehouseLocation hebben
        } else if (a.warehouseLocation === undefined) {
          return 1; // Verplaats object zonder warehouseLocation naar het einde van de array
        } else if (b.warehouseLocation === undefined) {
          return -1; // Verplaats object zonder warehouseLocation naar het einde van de array
        } else {
          return a.warehouseLocation - b.warehouseLocation; // Sorteer op warehouseLocation
        }
      });

      setInProducts(json.products);
      console.log(json.products);
    }
  }

  //get single replenishment
  useEffect(() => {
    let intervalId;

    fetchConcept();
    intervalId = setInterval(fetchConcept, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [displayAddScreen, setDisplayAddScreen] = useState(false);

  //handle adding products to array
  function handleAddProducts() {
    setDisplayAddScreen(!displayAddScreen);
  }

  //handle add input fields
  const [ean, setEan] = useState();
  const [quantity, setQuantity] = useState();

  function handleChangeQuantity(event) {
    setQuantity(+event.target.value);
  }

  function handleChangeEan(event) {
    setEan(event.target.value.trim());
  }

  async function AddToPicklist() {
    console.log(ean, quantity);

    const response = await fetch(`${hostNameServer}/api/products/picklist/addproduct`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        idOfDocument: documentId,
        ean,
        numberOfArticles: quantity,
      }),
    });

    if (response.ok) {
      setDisplayAddScreen(false);
      setQuantity(null);
      setEan(null);
      fetchConcept();
    }
    if (!response.ok) {
      console.log(response);
    }
  }

  //handle copy to clipboard
  const [copied, setCopied] = useState(false);
  let resultString = '';

  useEffect(() => {
    if (inProducts && inProducts.length > 0) {
      for (const object of inProducts) {
        resultString += `${object.ean}, ${object.numberOfArticles}\n`;
      }
      setCopied(resultString);
    }
  }, [inProducts]);

  const [progress, setProgress] = useState(75);
  const progressBarWidth = { width: `${progress}%` };

  useEffect(() => {
    if (inProducts) {
      const totNumberOfProducts = inProducts.length;

      const pickedProducts = inProducts.filter((item) => item.picked === true).length;

      setProgress(Number(((pickedProducts / totNumberOfProducts) * 100).toFixed(0)));
      console.log(progress);
    }
  }, [inProducts]);

  //trigger fetch from components to refresh progressbar
  function triggerFetch() {
    fetchConcept();
  }

  function handleCopyClick() {
    window.open('https://partner.bol.com/sdd/replenishments/draft', '_blank');
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-6 verticalAlign">
            <Link to={`/picklist`}>
              <span>Ga terug</span>
            </Link>
          </div>
          <div className="col-6 justify-content-end d-flex">
            <button onClick={handleAddProducts} type="button" class="btn btn-primary">
              {!displayAddScreen ? 'Toevoegen' : 'Annuleren'}
            </button>
          </div>
          {displayAddScreen && (
            <form>
              <div class="form-group">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <label for="">Ean:</label>
                    <input type="text" class="form-control" id="" placeholder="Ean" onChange={handleChangeEan} />
                  </div>
                  <div className="col-12 col-lg-6">
                    <label for="">Aantal artikelen</label>
                    <input type="number" class="form-control" id="" placeholder="Aantal stuks" onChange={handleChangeQuantity} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-0 col-lg-9"></div>
                  <div className="col-12 justify-content-end d-flex pt-3 col-lg-3">
                    <button onClick={AddToPicklist} type="button" class="btn btn-primary">
                      Toevoegen aan Picklist
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        {!products && (
          <div class="text-center">
            <div class="spinner-border" role="status"></div>
          </div>
        )}
        {products && (
          <div>
            <div className="row">
              <p className="conceptSubTitle">Concept Zending</p>
              <h1 className="picklistPageTitle">{products.title}</h1>

              <div>
                {inProducts &&
                  inProducts.map((concept) => <NewPicklist concept={concept} key={concept._id} document={products._id} triggerFetch={triggerFetch} />)}
              </div>
              {inProducts.length < 1 && (
                <div className="makeCenter">
                  <p>Geen producten gevonden...</p>
                </div>
              )}
            </div>
            {progress !== 100 && (
              <div className="row" style={{ marginBottom: '25px' }}>
                <CopyToClipboard text={copied}>
                  <button className="btn btn-primary">Kopieren naar Klembord</button>
                </CopyToClipboard>
              </div>
            )}

            <div class="progressBar">
              {progress === 100 ? (
                <div>
                  <CopyToClipboard text={copied}>
                    <button className="btn btn-primary fullWidthCopyBar" onClick={handleCopyClick}>
                      Kopieren naar Klembord
                    </button>
                  </CopyToClipboard>
                </div>
              ) : (
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-striped bg-success progress-bar-animated"
                    role="progressbar"
                    style={progressBarWidth}
                    aria-valuenow={progressBarWidth}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {`${progress}%`}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PicklistPage;
