import { useToast } from '../../context/ToastContext';
import hostNameServer from '../../configHostname';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';

export function useGetDatabaseDocument() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const showToast = useToast();

  const getDatabaseDocument = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${hostNameServer}/api/database/get/${data}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
        });

        const json = await response.json();

        if (response.ok) {
          resolve(json);
        }

        if (response.statusText === 'Unauthorized') {
          showToast('Login opnieuw in');
          logout();
          reject();
        }
      } catch (e) {
        showToast('Oops. Er ging iets mis!');
        reject();
      }
    });
  };

  return getDatabaseDocument;
}
