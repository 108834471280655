import { ProductContext } from "../context/ProductsContext";
import { useContext } from "react";

export const useProductContext = () => {
    const context = useContext(ProductContext)

    if(!context) {
        throw Error("Tracker context is outside of Context")
    }

    return context
}