import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useRefresh } from '../hooks/useRefresh';
import ProductCard from '../components/ProductCard';
// import { useProductContext } from '../hooks/useProductContext';
import CurrencyFormat from 'react-currency-format';
import { useLogout } from '../hooks/useLogout';
import hostNameServer from '../configHostname';
import FetchProducts from '../controllers/FetchAllProductData';
import ProductNavBar from '../components/ProductNav';

function Products() {
  const { user } = useAuthContext();
  const token = user.token;
  const { logout } = useLogout();
  useRefresh();
  //   const { globalProductState, dispatch } = useProductContext();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiCall, setApiCall] = useState(false);

  // fetch product data
  useEffect(() => {
    async function fetchAllData() {
      const response = await fetch(
        `${hostNameServer}/api/products/getalloffers`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const json = await response.json();

      if (response.ok) {
        setProducts(json);
        setLoading(false);
      }
      if (response.statusText === 'Unauthorized') {
        logout();
      }
    }
    fetchAllData();
  }, []);

  //number of products
  const [numberOfProducts, setNumberOfProducts] = useState();

  useEffect(() => {
    setNumberOfProducts(products.length);
  }, [products]);

  //determine number of articles
  const [totalArticles, setTotalArticles] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      return (
        sum +
        (parseInt(obj.correctedStock) || 0) +
        (parseInt(obj.stocklevel) || 0) +
        (parseInt(obj.expectedStock) || 0)
      );
    }, 0);

    setTotalArticles(totalSum);
  }, [products]);

  //detemine totalProfit
  const [totalProfitNumber, setTotalProfitNumber] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      const correctedStock = parseInt(obj.correctedStock) || 0;
      const stocklevel = parseInt(obj.stocklevel) || 0;
      const expectedStock = parseInt(obj.expectedStock) || 0;
      const profit = parseFloat(obj.profit) || 0;

      return (
        sum + (correctedStock + stocklevel + expectedStock) * (profit || 1)
      );
    }, 0);

    setTotalProfitNumber(totalSum);
  }, [products]);

  //detemine totalpurchaseprice
  const [totalPurchasePriceNumber, setTotalPurchasePriceNumber] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      const correctedStock = parseInt(obj.correctedStock) || 0;
      const stocklevel = parseInt(obj.stocklevel) || 0;
      const expectedStock = parseInt(obj.expectedStock) || 0;
      const purchase = parseFloat(obj.purchaseprice) || 0;

      return (
        sum + (correctedStock + stocklevel + expectedStock) * (purchase || 1)
      );
    }, 0);

    setTotalPurchasePriceNumber(totalSum);
  }, [products]);

  //detemine totalprice
  const [totalPriceNumber, setTotalPriceNumber] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      const correctedStock = parseInt(obj.correctedStock) || 0;
      const stocklevel = parseInt(obj.stocklevel) || 0;
      const expectedStock = parseInt(obj.expectedStock) || 0;
      const price = parseFloat(obj.bundlePricesPrice) || 0;

      return sum + (correctedStock + stocklevel + expectedStock) * (price || 1);
    }, 0);

    setTotalPriceNumber(totalSum);
  }, [products]);

  return (
    <div>
      <ProductNavBar activeTab={'producten'} />

      <div className="productcard">
        <div className="row">
          <div className="col-lg-2 bannerDiv">
            <p className="bannerTitle">Producten</p>
            {numberOfProducts ? (
              <p className="bannerNumber numberProducts">{numberOfProducts}</p>
            ) : (
              <div class="spinner">
                <div class="spinner-border" role="status"></div>
              </div>
            )}
            <span className="articleBanner">{totalArticles} artikelen</span>
          </div>

          <div className="col-lg-2 bannerDiv">
            <p className="bannerTitle">Inkoopwaarde</p>
            {totalPurchasePriceNumber ? (
              <p className="bannerNumber">
                <CurrencyFormat
                  value={totalPurchasePriceNumber}
                  displayType={'text'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  thousandSeparator={'.'}
                />
              </p>
            ) : (
              <div class="spinner">
                <div class="spinner-border" role="status"></div>
              </div>
            )}
          </div>
          <div className="col-lg-2 bannerDiv">
            <p className="bannerTitle">Verkoopwaarde</p>
            {totalPriceNumber ? (
              <p className="bannerNumber">
                <CurrencyFormat
                  value={totalPriceNumber}
                  displayType={'text'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  thousandSeparator={'.'}
                />
              </p>
            ) : (
              <div class="spinner">
                <div class="spinner-border" role="status"></div>
              </div>
            )}
          </div>
          <div className="col-lg-2 bannerDiv">
            <p className="bannerTitle">Winstwaarde</p>
            {totalProfitNumber ? (
              <p className="bannerNumber">
                <CurrencyFormat
                  value={totalProfitNumber}
                  displayType={'text'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  thousandSeparator={'.'}
                />
              </p>
            ) : (
              <div class="spinner">
                <div class="spinner-border" role="status"></div>
              </div>
            )}
          </div>
          <div className="col-lg-2 bannerDiv">
            <p className="bannerTitle">Waardeontwikkeling</p>
            {totalProfitNumber ? (
              <p className="bannerNumber">
                <CurrencyFormat
                  value={totalProfitNumber + totalPurchasePriceNumber}
                  displayType={'text'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  thousandSeparator={'.'}
                />
              </p>
            ) : (
              <div class="spinner">
                <div class="spinner-border" role="status"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row rowTitles">
        <div className="col-lg-1"></div>
        <div className="col-lg-3">
          <span className="rowDescription">Product</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Voorraad</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Prognose</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Conditie</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Verkoopprijs</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Winstmarge</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Inkoopwaarde</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Verkoopwaarde</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription makeCenter">Winstwaarde</span>
        </div>
      </div>
      {loading && !products && (
        <div class="text-center">
          <div class="spinner-border" role="status"></div>
        </div>
      )}
      <h1>
        {products &&
          products.map((product) => (
            <ProductCard product={product} key={product.offerId} />
          ))}
      </h1>
    </div>
  );
}

export default Products;
