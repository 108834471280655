import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useRefresh } from '../hooks/useRefresh';
import ProductCard from '../components/ProductCard';
// import { useProductContext } from '../hooks/useProductContext';
import CurrencyFormat from 'react-currency-format';
import { useLogout } from '../hooks/useLogout';
import hostNameServer from '../configHostname';
import FetchProducts from '../controllers/FetchAllProductData';
import ProductNavBar from '../components/ProductNav';

function ProductsV2() {
  const { user } = useAuthContext();
  const token = user.token;
  const { logout } = useLogout();
  useRefresh();
  //   const { globalProductState, dispatch } = useProductContext();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiCall, setApiCall] = useState(false);

  // fetch product data
  useEffect(() => {
    async function fetchAllData() {
      const response = await fetch(`${hostNameServer}/api/products/getalloffers`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const json = await response.json();

      if (response.ok) {
        const sortJson = json.sort((a, b) => b.forecast - a.forecast);
        const newArray = await fetchIncomingShipmentData(sortJson);

        setProducts(newArray);
        setLoading(false);
      }
      if (response.statusText === 'Unauthorized') {
        logout();
      }
    }
    fetchAllData();
  }, []);

  //fetch incoming shipments

  async function fetchIncomingShipmentData(data) {
    const response = await fetch(`${hostNameServer}/api/products/incomingshipment`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const json = await response.json();

    if (response.ok) {
      const newArrayWithExpectedStock = addExpectedStock(json, data);
      console.log('firefire');
      console.log(newArrayWithExpectedStock);
      return newArrayWithExpectedStock;
    }
    if (response.statusText === 'Unauthorized') {
      logout();
    }
  }

  //function to add expectedStock from old to new array
  function addExpectedStock(array1, array2) {
    const newArrayWithProduct = [];

    for (const object of array1) {
      const products = JSON.parse(object.products);
      for (const object of products) {
        newArrayWithProduct.push(object);
      }
    }

    const resultatenObject = {};

    newArrayWithProduct.forEach((object) => {
      const stockValue = parseInt(object.stock, 10);

      if (resultatenObject.hasOwnProperty(object.offerId)) {
        resultatenObject[object.offerId].stock += stockValue;
      } else {
        resultatenObject[object.offerId] = { offerId: object.offerId, stock: stockValue };
      }
    });

    const resultatenArray = Object.values(resultatenObject);

    const resultArray = array2.map((item) => {
      const matchingItem = resultatenArray.find((x) => x.offerId === item.offerId);

      if (matchingItem) {
        return {
          ...item,
          expectedStock: +matchingItem.stock,
        };
      }
      return item;
    });

    return resultArray;
  }

  //number of products
  const [numberOfProducts, setNumberOfProducts] = useState();

  useEffect(() => {
    setNumberOfProducts(products.length);
  }, [products]);

  //determine number of articles
  const [totalArticles, setTotalArticles] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      return sum + (parseInt(obj.lvbStock) || 0) + (parseInt(obj.ownedStock) || 0) + (parseInt(obj.expectedStock) || 0);
    }, 0);

    setTotalArticles(totalSum);
  }, [products]);

  //detemine totalProfit
  const [totalProfitNumber, setTotalProfitNumber] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      const correctedStock = parseInt(obj.LVBstock) || 0;
      const stocklevel = parseInt(obj.ownedStock) || 0;
      const expectedStock = parseInt(obj.expectedStock) || 0;
      const profit = parseFloat(obj.profit) || 0;

      return sum + (correctedStock + stocklevel + expectedStock) * (profit || 1);
    }, 0);

    setTotalProfitNumber(totalSum);
  }, [products]);

  //detemine totalpurchaseprice
  const [totalPurchasePriceNumber, setTotalPurchasePriceNumber] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      const correctedStock = parseInt(obj.LVBstock) || 0;
      const stocklevel = parseInt(obj.ownedStock) || 0;
      const expectedStock = parseInt(obj.expectedStock) || 0;
      const purchase = parseFloat(obj.purchasePrice) || 0;

      return sum + (correctedStock + stocklevel + expectedStock) * (purchase || 1);
    }, 0);

    setTotalPurchasePriceNumber(totalSum);
  }, [products]);

  //detemine totalprice
  const [totalPriceNumber, setTotalPriceNumber] = useState();

  useEffect(() => {
    const totalSum = products.reduce((sum, obj) => {
      const correctedStock = parseInt(obj.LVBstock) || 0;
      const stocklevel = parseInt(obj.ownedStock) || 0;
      const expectedStock = parseInt(obj.expectedStock) || 0;
      const price = parseFloat(obj.price) || 0;

      return sum + (correctedStock + stocklevel + expectedStock) * (price || 1);
    }, 0);

    setTotalPriceNumber(totalSum);
  }, [products]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [searchTerm, setSearchTerm] = useState();
  const [searchedProducts, setSearchedProducts] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    if (searchTimeout) {
      clearTimeout(searchTimeout); // Annuleer vorige time-out als deze nog niet is uitgevoerd
    }

    // Stel een nieuwe time-out in om te zoeken na 500 ms
    const timeoutId = setTimeout(() => {
      performSearch(newSearchTerm);
    }, 500);

    setSearchTimeout(timeoutId);
  };

  const performSearch = (term) => {
    const searchTermLowerCase = term.toLowerCase();
    const regexSearchTerm = new RegExp(searchTermLowerCase, 'i'); // 'i' maakt de zoekterm niet-hoofdlettergevoelig

    const results = products.filter((item) => {
      const productTitle = item.productTitle?.toLowerCase();
      const ean = item.ean?.toLowerCase();
      const shortTitle = item.shortTitle?.toLowerCase();

      return regexSearchTerm.test(productTitle) || regexSearchTerm.test(ean) || regexSearchTerm.test(shortTitle) || regexSearchTerm.test(item.bsku);
    });

    setSearchedProducts(results);
  };

  useEffect(() => {
    console.log(searchTerm);
    if (searchTerm === '') {
      console.log('fire');
      setSearchedProducts(null);
      console.log(searchedProducts);
    }
  }, [searchTerm]);

  return (
    <div>
      <div className="row">
        <div className="col-9">
          <ProductNavBar activeTab={'productenv2'} />
        </div>
        <div className="col-lg-2 col-12 mb-2">
          <input class="form-control py-2 border" type="search" value={searchTerm} placeholder="Zoeken..." onChange={handleSearch} />
        </div>
        <div className="col-1 hideOnMobile d-flex flex-row justify-content-end mt-1">
          <div class="form-check form-switch hideOnMobile">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleCheckboxChange} />
          </div>
        </div>
      </div>

      {isChecked && (
        <div className="productcard hideOnMobile">
          <div className="row ">
            <div className="col-lg-2 bannerDiv">
              <p className="bannerTitle">Producten</p>
              {numberOfProducts ? (
                <p className="bannerNumber numberProducts">{numberOfProducts}</p>
              ) : (
                <div class="spinner">
                  <div class="spinner-border" role="status"></div>
                </div>
              )}
              <span className="articleBanner">{totalArticles} artikelen</span>
            </div>

            <div className="col-lg-2 bannerDiv">
              <p className="bannerTitle">Inkoopwaarde</p>
              {totalPurchasePriceNumber ? (
                <p className="bannerNumber">
                  <CurrencyFormat
                    value={totalPurchasePriceNumber}
                    displayType={'text'}
                    decimalScale={2}
                    prefix={'€'}
                    allowNegative={false}
                    decimalSeparator={','}
                    placeholder={'€0,00'}
                    fixedDecimalScale={true}
                    thousandSeparator={'.'}
                  />
                </p>
              ) : (
                <div class="spinner">
                  <div class="spinner-border" role="status"></div>
                </div>
              )}
            </div>
            <div className="col-lg-2 bannerDiv">
              <p className="bannerTitle">Verkoopwaarde</p>
              {totalPriceNumber ? (
                <p className="bannerNumber">
                  <CurrencyFormat
                    value={totalPriceNumber}
                    displayType={'text'}
                    decimalScale={2}
                    prefix={'€'}
                    allowNegative={false}
                    decimalSeparator={','}
                    placeholder={'€0,00'}
                    fixedDecimalScale={true}
                    thousandSeparator={'.'}
                  />
                </p>
              ) : (
                <div class="spinner">
                  <div class="spinner-border" role="status"></div>
                </div>
              )}
            </div>
            <div className="col-lg-2 bannerDiv">
              <p className="bannerTitle">Winstwaarde</p>
              {totalProfitNumber ? (
                <p className="bannerNumber">
                  <CurrencyFormat
                    value={totalProfitNumber}
                    displayType={'text'}
                    decimalScale={2}
                    prefix={'€'}
                    allowNegative={false}
                    decimalSeparator={','}
                    placeholder={'€0,00'}
                    fixedDecimalScale={true}
                    thousandSeparator={'.'}
                  />
                </p>
              ) : (
                <div class="spinner">
                  <div class="spinner-border" role="status"></div>
                </div>
              )}
            </div>
            <div className="col-lg-2 bannerDiv">
              <p className="bannerTitle">Waardeontwikkeling</p>
              {totalProfitNumber ? (
                <p className="bannerNumber">
                  <CurrencyFormat
                    value={totalProfitNumber + totalPurchasePriceNumber}
                    displayType={'text'}
                    decimalScale={2}
                    prefix={'€'}
                    allowNegative={false}
                    decimalSeparator={','}
                    placeholder={'€0,00'}
                    fixedDecimalScale={true}
                    thousandSeparator={'.'}
                  />
                </p>
              ) : (
                <div class="spinner">
                  <div class="spinner-border" role="status"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="row rowTitles hideOnMobile">
        <div className="col-lg-1"></div>
        <div className="col-lg-3">
          <span className="rowDescription">Product</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Voorraad</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Prognose</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Conditie</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Verkoopprijs</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Winstmarge</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Inkoopwaarde</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Verkoopwaarde</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription makeCenter">Winstwaarde</span>
        </div>
      </div>
      {loading && (
        <div class="text-center pt-5">
          <div class="spinner-border" role="status"></div>
        </div>
      )}
      <h1>
        {searchTerm && searchedProducts ? (
          searchedProducts.map((product) => <ProductCard product={product} key={product.offerId} />)
        ) : (
          <div>
            {products
              .filter((product) => product.onHold === false)
              .map((product) => (
                <ProductCard product={product} key={product.offerId} />
              ))}

            {!loading && products.filter((product) => product.onHold === true) && <h2>On Hold</h2>}
            {products
              .filter((product) => product.onHold === true)
              .map((product) => (
                <div>
                  <ProductCard product={product} key={product.offerId} />
                </div>
              ))}
          </div>
        )}
      </h1>
    </div>
  );
}

export default ProductsV2;
