import { React, useEffect, useState } from "react";

import { useRefresh } from "../hooks/useRefresh";
import LVBcard from "../components/LVBcard";
import { useProductContext } from "../hooks/useProductContext";
import { useAuthContext } from "../hooks/useAuthContext";
import hostNameServer from "../configHostname";
import ProductNavBar from "../components/ProductNav";
import { useNavigate } from "react-router-dom";
import MultipleSelectChip from "../components/LVBShipment/MultiSelectConceptShipments";

function LVBShipment() {
  const { user } = useAuthContext();
  const token = user.token;
  useRefresh();
  const navigate = useNavigate();

  const [conceptArticles, setConceptArticles] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // fetch product data
  useEffect(() => {
    fetchAllData();
  }, []);

  async function fetchAllData() {
    const response = await fetch(
      `${hostNameServer}/api/products/getalloffers`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const json = await response.json();
    console.log(json);

    if (response.ok) {
      const sortJson = json.sort((a, b) => b.forecast - a.forecast);
      setProducts(sortJson);
      setLoading(false);
    }
  }

  async function handleClick() {
    console.log(conceptArticles);
    saveConceptShipment(conceptArticles);
    window.location.replace("/picklist");
  }

  //save concept lvb shipment to db
  async function saveConceptShipment(replenishmentItems) {
    const response = await fetch(
      `${hostNameServer}/api/products/saveconceptreplenishment`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          replenishmentItems,
        }),
      },
    );
    if (response.ok) {
    }
    const json = await response.json();
  }

  const handleStockChange = (offerId, newValue) => {
    // Zoek het object met het gewenste offerId
    const product = products.find((item) => item.offerId === offerId);
    if (product) {
      // Pas de waarde van requiredstockdays aan
      product.requiredstockdays1 = Number(newValue);
      // console.log('requiredstockdays gewijzigd:', product.requiredstockdays);
      // Gebruik de filter-methode om objecten met requiredstockdays1 > 1 te selecteren
      var geselecteerdeProducten = products.filter(function (product) {
        return product.requiredstockdays1 >= 1;
      });
      // Gebruik de map-methode om een nieuwe array van objecten met alleen 'ean' en 'requiredstockdays1' te maken
      var geselecteerdeKeys = geselecteerdeProducten.map(function (product) {
        return {
          ean: product.ean,
          requiredstockdays1: product.requiredstockdays1,
        };
      });
      // Log de geselecteerde keys naar de console

      setConceptArticles(geselecteerdeKeys);
    } else {
      console.log("Geen object gevonden met offerId:", offerId);
    }
  };

  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (conceptArticles && conceptArticles.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [conceptArticles]);

  const [isChecked, setIsChecked] = useState(false);
  const [productsWithAnnouncedQuantities, setProductsWithAnnouncedQuantities] =
    useState([]);

  // Declaratie van de originele staat
  const [originalProducts, setOriginalProducts] = useState([]);
  const [
    originalProductsWithAnnouncedQuantities,
    setOriginalProductsWithAnnouncedQuantities,
  ] = useState([]);

  useEffect(() => {
    setOriginalProducts(products);
    setOriginalProductsWithAnnouncedQuantities(productsWithAnnouncedQuantities);
  }, [productsWithAnnouncedQuantities, products]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);

    if (!isChecked) {
      const filter = products.filter((item) => item.ownedStock !== 0);
      const filterQuantityAnnounced = productsWithAnnouncedQuantities.filter(
        (item) => item.ownedStock !== 0,
      );
      setProducts(filter);
      setProductsWithAnnouncedQuantities(filterQuantityAnnounced);
    } else {
      setProducts(originalProducts);
      setProductsWithAnnouncedQuantities(
        originalProductsWithAnnouncedQuantities,
      );
    }
  };

  const [extendedSelectedShipmentInfo, setExtendedSelectedShipmentInfo] =
    useState([]);

  function getExtendedAnnouncedShipmentInfo(info) {
    setExtendedSelectedShipmentInfo(info);
  }

  const updateProductsQuantity = () => {
    const updatedProducts = [...products];

    updatedProducts.forEach((product) => {
      delete product.quantityAnnounced;
      extendedSelectedShipmentInfo.forEach((shipmentInfo) => {
        const productLine = shipmentInfo.lines.find(
          (line) => line.ean === product.ean,
        );

        if (productLine) {
          if (product.quantityAnnounced) {
            product.quantityAnnounced += productLine.quantityAnnounced;
          } else {
            product.quantityAnnounced = productLine.quantityAnnounced;
          }
        }
      });
    });

    setProductsWithAnnouncedQuantities(updatedProducts);
  };

  useEffect(() => {
    updateProductsQuantity();
  }, [extendedSelectedShipmentInfo]);

  return (
    <div>
      <div className="row mobile-pt-3">
        <div className="col-7">
          <ProductNavBar activeTab={"lvb"} />
        </div>
        <div className="col-lg-5 col-6 d-flex align-items-center justify-content-between">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              onChange={handleCheckboxChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Leeg verbergen
            </label>
          </div>
          <div>
            <MultipleSelectChip
              getExtendedAnnouncedShipmentInfo={
                getExtendedAnnouncedShipmentInfo
              }
            />
          </div>

          <div className="">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClick}
              disabled={disable}
            >
              Concept Aanmaken
            </button>
          </div>
        </div>
      </div>

      <div className="row rowTitles hideOnMobile">
        <div className="col-lg-2"></div>
        <div className="col-lg-3">
          <span className="rowDescription">Product</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Opslag</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">LVB Voorraad</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Prognose</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Voorraaddagen</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Aanvuladvies</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Na aanvullen</span>
        </div>
      </div>
      {loading && (
        <div class="text-center pt-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <h1>
        {productsWithAnnouncedQuantities &&
        productsWithAnnouncedQuantities.length > 0 &&
        extendedSelectedShipmentInfo.length > 0
          ? productsWithAnnouncedQuantities.map((product) => (
              <LVBcard
                product={product}
                key={product.offerId}
                onStockChange={handleStockChange}
              />
            ))
          : products &&
            products
              .filter((product) => product.onHold === false)
              .map((product) => (
                <LVBcard
                  product={product}
                  key={product.offerId}
                  onStockChange={handleStockChange}
                />
              ))}

        {!loading && products.filter((product) => product.onHold === true) && (
          <h2>On Hold</h2>
        )}
        {products &&
          products
            .filter((product) => product.onHold === true)
            .map((product) => (
              <div>
                <LVBcard
                  product={product}
                  key={product.offerId}
                  onStockChange={handleStockChange}
                />
              </div>
            ))}
      </h1>
    </div>
  );
}

export default LVBShipment;
