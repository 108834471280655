import React from 'react'

function IncomingShipmentsCard({ product }) {


    return (
        <div>
            <div class="container-fluid">
                <div class="row">

                    <div class="col-md-10 ellipsis-text">
                        {product.selectedOption.label}
                    </div>
                    <div class="col-md-2">
                        {product.inputValue}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IncomingShipmentsCard