import { React, useState, useEffect } from 'react';
import ProductNavBar from '../components/ProductNav';
import hostNameServer from '../configHostname';
import ConceptReplenishment from '../components/ConceptReplenishments';
import { useRefresh } from '../hooks/useRefresh';

function Picklist() {
  useRefresh();
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;
  const [loading, setLoading] = useState(false);

  const [conceptReplenishments, setConceptReplenishments] = useState();

  useEffect(() => {
    async function fetchAllData() {
      const response = await fetch(`${hostNameServer}/api/products/getallreplenishments`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const json = await response.json();
      console.log(json);
      if (response.ok) {
        setConceptReplenishments(json);
      }
    }
    fetchAllData();
  }, []);

  //remove deleted object from array
  function removeFromArray(id) {
    const newArray = conceptReplenishments.filter((item) => item._id !== id);
    setConceptReplenishments(newArray);
  }

  return (
    <div>
      <div className="row">
        <div className="col-8 pb-3">
          <ProductNavBar activeTab={'paklijst'} />
        </div>
        <div className="col-6"></div>
      </div>
      {!conceptReplenishments && (
        <div class="text-center pt-5">
          <div class="spinner-border" role="status"></div>
        </div>
      )}
      {conceptReplenishments && conceptReplenishments.length < 1 ? (
        <div>
          <p className="makeCenter">Geen beschikbare concept zendingen...</p>
        </div>
      ) : (
        <div>
          <h1>
            {conceptReplenishments &&
              conceptReplenishments.map((concept) => <ConceptReplenishment concept={concept} key={concept._id} removeFromArray={removeFromArray} />)}
          </h1>
        </div>
      )}
    </div>
  );
}

export default Picklist;
