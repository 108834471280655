import Card from './UI/Card';
import TextField from './UI/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import IconButton from '@mui/material/IconButton';
import { useUpdateDatabaseDocument } from '../controllers/databaseControllers/updateDocument';
import { useState } from 'react';

import { databaseDocumentTypes } from '../Utils/Types/databaseDocumentTypes';
import { useDeleteDatabaseDocument } from '../controllers/databaseControllers/deleteDocument';

const TraceCodeCard = ({ product, handleDeleteRow, handleReceivedRow }) => {
  const deleteDatabaseDocument = useDeleteDatabaseDocument();
  const updateDatabaseDocument = useUpdateDatabaseDocument();
  const [received, setReceived] = useState(product.received || false);

  async function handleDelete() {
    const data = {
      collection: databaseDocumentTypes.tracecodes,
      documentId: product._id,
    };
    const deletedDocument = await deleteDatabaseDocument(data);
    handleDeleteRow(deletedDocument.deletedDocument);
  }

  async function handleReceived() {
    const DBkey = 'received';
    const receivedState = product.received ? false : true;

    const data = {
      collection: databaseDocumentTypes.tracecodes,
      documentId: product._id,
      fields: {
        [DBkey]: receivedState,
      },
      successMessage: product.received || false ? 'Zending is onderweg' : 'Zending is ontvangen',
    };

    const updatedDoc = await updateDatabaseDocument(data);
    handleReceivedRow(updatedDoc.updatedDocument);
  }

  return (
    <Card>
      <div className="row">
        <div className="col-lg-5 verticalAlign">
          <TextField
            value={product.title}
            databaseKey={'title'}
            editable
            collection={databaseDocumentTypes.tracecodes}
            documentId={product._id}
            className={'ml-3 tracecodeTitle'}
          />
          <TextField
            value={product.products}
            databaseKey={'products'}
            editable
            collection={databaseDocumentTypes.tracecodes}
            documentId={product._id}
            className={'tracecodeProducts'}
          />
        </div>

        <div className="col-lg-2 verticalAlign">
          <TextField
            value={product.tracecode}
            databaseKey={'tracecode'}
            editable
            collection={databaseDocumentTypes.tracecodes}
            link
            linkUrl={product.link}
            documentId={product._id}
          />
        </div>
        <div className="col-lg-2 col-4 verticalAlign">
          <TextField
            value={product.startDate || 'Geen datum'}
            databaseKey={'startDate'}
            editable
            collection={databaseDocumentTypes.tracecodes}
            documentId={product._id}
            type={'date'}
          />
        </div>
        <div className="col-lg-2 col-4 verticalAlign">
          <TextField
            value={product.endDate || 'Geen Datum'}
            databaseKey={'endDate'}
            editable
            collection={databaseDocumentTypes.tracecodes}
            documentId={product._id}
            type={'date'}
          />
        </div>
        <div className="col-lg-1 col-4 d-flex align-items-center">
          <IconButton>
            <CallReceivedIcon className="callReceivedIcon" onClick={handleReceived} />
          </IconButton>
          <IconButton>
            <DeleteIcon className="deleteIcon" onClick={handleDelete} />
          </IconButton>
        </div>
      </div>
    </Card>
  );
};

export default TraceCodeCard;
