import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useRefresh } from '../hooks/useRefresh';
import CurrencyFormat from 'react-currency-format';
import hostNameServer from '../configHostname';
import StockCard from '../components/StockCard';
import warehouse from '../assets/Pallets.jpg';
import { Link } from 'react-router-dom';

function ProductPage() {
  useRefresh();
  const { ean, picklist } = useParams();
  const { user } = useAuthContext();
  const token = user.token;
  const [inProduct, setInProduct] = useState();
  const [VAT, setVAT] = useState();
  const [commission, setCommission] = useState();
  const [profit, setProfit] = useState(0);

  // fetch product data
  useEffect(() => {
    async function fetchAllData() {
      const response = await fetch(`${hostNameServer}/api/products/getalloffers`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const json = await response.json();

      if (response.ok) {
        const check = vindObjectMetEAN(json, ean);
        setInProduct(check);

        if (inProduct && inProduct.shortTitle) {
          setShortTitle(inProduct.shortTitle);
          console.log(inProduct.shortTitle);
        } else {
          console.log('nope');
        }

        setProfit(check.profit);
      }
    }
    fetchAllData();
  }, []);

  //function to find the correct product
  function vindObjectMetEAN(array, ean) {
    for (const object of array) {
      if (object.ean === ean) {
        return object;
      }
    }
    return null; // Als het object niet is gevonden, kun je bijvoorbeeld null retourneren
  }

  //if product found the set purchase price and LVB size
  useEffect(() => {
    if (inProduct) {
      console.log(inProduct);
      setPurchasePrice(inProduct.purchasePrice);
      setLvbMaat(inProduct.lvbSize);
    }
  }, [inProduct]);

  //calculate VAT
  useEffect(() => {
    if (inProduct) {
      //calc VAt
      const calcVat = (Number(inProduct.price) / 121) * 21;
      setVAT(calcVat);

      //calc Commission
      const calcCom = (Number(inProduct.commission) / 121) * 100;
      setCommission(calcCom);
    }
  }, [inProduct]);

  const lvbMaten = [
    { label: 'XXXS', value: 2.37 },
    { label: 'XXS', value: 3.02 },
    { label: 'XS', value: 4.5 },
    { label: 'S', value: 5.37 },
    { label: 'M', value: 5.52 },
    { label: 'L', value: 6.54 },
  ];

  const [lvbMaat, setLvbMaat] = useState();
  const [lvbName, setLVBName] = useState('');

  function handleLvbMaatChange(index) {
    setLvbMaat(lvbMaten[index].value);
    setLVBName(lvbMaten[index].label);
    sendLVBSIZE(lvbMaten[index].value);
  }

  async function sendLVBSIZE(lvbMaat) {
    const response = await fetch(`${hostNameServer}/api/products/updatelvbsize`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({ offerId: inProduct.offerId, value: lvbMaat }),
    });

    const json = await response.json();
    console.log(json);
  }

  //update stockprice in db
  const [purchasePrice, setPurchasePrice] = useState();

  async function updatePurchasePrice(event) {
    const updatedValue = event.target.value.replace('€', '').replace(',', '.');
    console.log('update purchase price is getriggert');
    setPurchasePrice(event.target.value);

    const response = await fetch(`${hostNameServer}/api/products/updatepurchaseprice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        offerId: inProduct.offerId,
        value: updatedValue,
      }),
    });

    const json = await response.json();
    console.log(json);
  }

  //update Profit Margin
  const [profitMargin, setProfitMargin] = useState();

  useEffect(() => {
    if (inProduct) {
      setProfitMargin(inProduct.price - VAT - purchasePrice - lvbMaat - inProduct.commission);
    }
  }, [inProduct, purchasePrice, lvbMaat]);

  useEffect(() => {
    async function updateProfitMargin() {
      const response = await fetch(`${hostNameServer}/api/products/updateprofit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          offerId: inProduct.offerId,
          value: profitMargin,
        }),
      });
    }
    if (inProduct) {
      updateProfitMargin();
    }
  }, [profitMargin, purchasePrice, lvbMaat]);

  const [isWindowVisible, setIsWindowVisible] = useState(false);

  const handleButtonClick = () => {
    setIsWindowVisible(!isWindowVisible);
  };

  const [stockMutations, setStockMutations] = useState();

  useEffect(() => {
    async function getStockMutations() {
      const response = await fetch(`${hostNameServer}/api/products/stockmutations/${inProduct.offerId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      });

      const json = await response.json();

      if (response.ok) {
        const sortedjson = json.sort((a, b) => new Date(b.date) - new Date(a.date));

        setStockMutations(sortedjson);
      }
    }
    if (inProduct) {
      getStockMutations();
    }
  }, [isWindowVisible, inProduct]);

  const [shortTitle, setShortTitle] = useState();

  //handle shortTitle in DB
  async function handleShortTitle(event) {
    setShortTitle(event.target.value);
  }

  useEffect(() => {
    if (inProduct) {
      async function updateShortTitle() {
        const response = await fetch(`${hostNameServer}/api/products/productpage/shorttitle`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            offerId: inProduct.offerId,
            value: shortTitle,
          }),
        });

        if (inProduct) {
          console.log('Succes');
        }
      }
      updateShortTitle();
    }
  }, [shortTitle]);

  const [warehouseNumber, setWarehouseNumber] = useState();

  //handle warehouse location in DB
  function handleWarehouseLocation(event) {
    setWarehouseNumber(event.target.value);
  }

  useEffect(() => {
    if (inProduct) {
      async function updatewarehouseLocation() {
        const response = await fetch(`${hostNameServer}/api/products/productpage/warehouselocation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            offerId: inProduct.offerId,
            value: warehouseNumber,
          }),
        });

        if (inProduct) {
          console.log('Succes');
        }
      }
      updatewarehouseLocation();
    }
  }, [warehouseNumber]);

  useEffect(() => {
    if (inProduct && inProduct.shortTitle) {
      setShortTitle(inProduct.shortTitle);
    }
    if (inProduct && inProduct.warehouseLocation) {
      setWarehouseNumber(inProduct.warehouseLocation);
    }
  }, [inProduct]);
  console.log(inProduct);
  return (
    <div>
      {picklist && (
        <Link to={`/picklist/${picklist}`}>
          <span>Ga terug naar Picklist</span>
        </Link>
      )}
      <div>
        <div className={`sliding-window ${isWindowVisible ? 'open' : ''}`}>
          {isWindowVisible && (
            <div className="window-content">{stockMutations && stockMutations.map((product) => <StockCard product={product} key={product.offerId} />)}</div>
          )}
        </div>
      </div>
      {inProduct && (
        <div class="container-fluid">
          <div className="row productRow">
            <div className="col-lg-2 text-center">
              <img src={inProduct.image} alt="" className="productPageImage" />
            </div>
            <div className="col-lg-10">
              <p className="productPageTitle">{inProduct.shortTitle || inProduct.productTitle}</p>
              <p className="productPageEan">EAN: {inProduct.ean}</p>
              <p className="productPageEan mb-2">BSKU: {inProduct.bsku}</p>
              <button onClick={handleButtonClick} className="btn btn-primary btn-orange btn-mutations">
                Voorraadmutaties
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 productRow productNumbers">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    Alternatieve titel
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control makeRight"
                  placeholder="Korte Titel..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleShortTitle}
                  value={shortTitle}
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    Magazijnlocatie
                  </span>
                </div>
                <input
                  type="number"
                  class="form-control makeRight"
                  placeholder="2"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleWarehouseLocation}
                  value={warehouseNumber}
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">
                    Verkoopprijs
                  </span>
                </div>
                <CurrencyFormat
                  value={Number(inProduct.price)}
                  displayType={'input'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  className="form-control makeRight"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  readOnly
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">
                    BTW (21%)
                  </span>
                </div>
                <CurrencyFormat
                  value={Number(VAT)}
                  displayType={'input'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  className="form-control makeRight"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  readOnly
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">
                    Commissie
                  </span>
                </div>
                <CurrencyFormat
                  value={commission}
                  displayType={'input'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  className="form-control makeRight"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  readOnly
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">
                    Inkoopprijs
                  </span>
                </div>
                <CurrencyFormat
                  value={purchasePrice}
                  displayType={'input'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={false}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  // fixedDecimalScale={}
                  className="form-control makeRight"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={updatePurchasePrice}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle toggleColor"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {lvbName || 'LVB Maat'}
                  </button>
                  <div className="dropdown-menu">
                    {lvbMaten.map((opt, index) => (
                      <a className="dropdown-item" href="#" onClick={() => handleLvbMaatChange(index)} key={index}>
                        {opt.label}
                      </a>
                    ))}
                  </div>
                </div>
                <CurrencyFormat
                  value={lvbMaat}
                  displayType="input"
                  decimalScale={2}
                  prefix="€"
                  allowNegative={true}
                  decimalSeparator=","
                  placeholder="€0,00"
                  fixedDecimalScale={true}
                  className="form-control lvbFormat"
                  aria-label="Text input with dropdown button"
                  readOnly
                />
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">
                    Winst per stuk
                  </span>
                </div>
                <CurrencyFormat
                  value={profit || 0}
                  displayType={'input'}
                  decimalScale={2}
                  prefix={'€'}
                  allowNegative={true}
                  decimalSeparator={','}
                  placeholder={'€0,00'}
                  fixedDecimalScale={true}
                  className="form-control makeRight"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  readOnly
                />
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2">
              <img className="imageMap" src={warehouse} usemap="#image-map" />

              {/* <map name="image-map">
                <area target="" coords="14,18,152,105" shape="rect" value="1" onClick={handleClick} />
              </map> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductPage;
