import { React, useState, useEffect } from "react";
import HistoryIcon from "@mui/icons-material/History";
import hostNameServer from "../configHostname";
import { useToast } from "../context/ToastContext";
import { useAuthContext } from "../hooks/useAuthContext";

const StockCard = ({ product }) => {
  const { user } = useAuthContext();
  const showToast = useToast();

  //Calc Date
  const [formattedDate, setFormattedDate] = useState();

  useEffect(() => {
    const dateTime = new Date(product.date);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1;
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    const formattedDateTime = `${day}-${month}-${year} om ${hours}:${minutes}`;

    setFormattedDate(formattedDateTime);
  }, []);

  const [stockChange, setStockChange] = useState();

  useEffect(() => {
    if (!product.oldstock) {
      setStockChange("Voorraadwijziging");
    }
    if (product.newstock < product.oldstock) {
      setStockChange("Voorraadafname");
    }
    if (product.newstock > product.oldstock) {
      setStockChange("Voorraadtoename");
    }
    if (product.newstock === product.oldstock) {
      setStockChange("Voorraadwijziging");
    }
  }, []);

  function handleRollBack() {
    updatedStockLevel(product.offerid, product.newstock);
  }

  async function updatedStockLevel(offerId, value) {
    try {
      const response = await fetch(
        `${hostNameServer}/api/products/updatestock`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ offerId: offerId, value: value }),
        },
      );

      const json = await response.json();
      console.log(json);

      if (response.ok) {
        showToast(`De voorraad is bijgewerkt naar: ${product.newstock}`);
      }
      if (!response.ok) {
        showToast("Bijwerken is niet geslaagd");
      }
    } catch (e) {
      console.log(e);
      showToast("Oeps, er ging iets fout.");
    }
  }

  return (
    <div className="stockCard">
      <span className="stockdatecard">{formattedDate}</span>
      <span className="stockcardTitle">{stockChange}</span>
      <span className="stockcardvalue">Vorig: {product.oldstock}</span>
      <div className="d-flex">
        <HistoryIcon
          role="button"
          className={"pe-1"}
          onClick={handleRollBack}
        />
        <span className="stockcardvalue">Nieuw: {product.newstock}</span>
      </div>
    </div>
  );
};

export default StockCard;
