import { React, useState, useEffect, useRef } from "react";
import hostNameServer from "../configHostname";
import { Link } from "react-router-dom";
import TextField from "./UI/TextField";
import ExploreOffIcon from "@mui/icons-material/ExploreOff";

const NewPicklist = ({ concept, document, triggerFetch }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const [picked, setPicked] = useState();
  const [pickLoader, setPickLoader] = useState(false);
  const [editRequiredStockNumber, setEditRequiredStockNumber] = useState(false);
  const [inputValue, setInputValue] = useState();
  const inputRef = useRef(null);

  //set pick state when concept changes
  useEffect(() => {
    setPicked(concept.picked);
  }, [concept.picked]);

  const [numberOfPickArticles, setNumberOfPickArticles] = useState();
  useEffect(() => {
    setNumberOfPickArticles(concept.numberOfArticles);
  }, [concept]);

  async function handlePickedStatus() {
    setPickLoader(true);
    setPicked(!picked);

    const response = await fetch(
      `${hostNameServer}/api/products/picklist/pickedproduct`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          idOfDocument: document,
          idOfProduct: concept._id,
          value: !picked,
        }),
      },
    );

    if (response.ok) {
      const json = await response.json();
      setPickLoader(false);
      triggerFetch();
    }
  }

  //change styles based on picked status
  const pickedMobile = picked
    ? "pickNumberMobilePicked pickNumberMobile"
    : "pickNumberMobile";
  const pickedImageState = picked
    ? "conceptProductImagePicked conceptProductImage"
    : "conceptProductImage";
  const pickedTitleState = picked
    ? "conceptPorductTitle conceptPorductTitlePicked"
    : "conceptPorductTitle";
  const conceptExtraInfo = picked
    ? "conceptExtraInfo conceptExtraInfoPicked"
    : "conceptExtraInfo";
  const pickedDesktop = picked ? "pickNumber pickNumberPicked" : "pickNumber";

  //-------------------------------------------------------------------handle edit mode
  const [editMode, setEditMode] = useState(false);

  function handleEdit() {
    setDisplayPickNumber(false);
    setEditMode(true);
  }

  function exitEditMode() {
    setEditMode(false);
    setDisplayPickNumber(true);
    setInputValue();
  }

  async function saveEditMode() {
    if (inputValue > 0) {
      setNumberOfPickArticles(inputValue);
      setEditMode(false);
      setDisplayPickNumber(true);
      await handleStockUpdate();
      setInputValue();
    } else {
      setEditMode(false);
      setDisplayPickNumber(true);
      setInputValue();
    }
  }

  function onChange(event) {
    setInputValue(event.target.value);
  }

  //function to update required number of stock to be picked
  async function handleStockUpdate() {
    const response = await fetch(
      `${hostNameServer}/api/products/picklist/updateproduct`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          idOfDocument: document,
          idOfProduct: concept._id,
          value: inputValue,
        }),
      },
    );

    if (response.ok) {
      const json = await response.json();
      triggerFetch();
    }
  }

  //-------------------------------------------------------------------handle delete mode
  const [deleteMode, setDeleteMode] = useState();

  function handleDelete() {
    setDeleteMode(true);
    setDisplayPickNumber(false);
  }

  function exitDeleteMode() {
    setDeleteMode(false);
    setDisplayPickNumber(true);
  }

  function definitiveDelete() {
    setDeleteMode(false);
    setDisplayPickNumber(true);
    deleteProductFromDB();
  }

  async function deleteProductFromDB() {
    const response = await fetch(
      `${hostNameServer}/api/products/picklist/deleteproduct`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          idOfDocument: document,
          idOfProduct: concept._id,
        }),
      },
    );

    if (response.ok) {
      const json = await response.json();
      triggerFetch();
    }
  }

  //display picknumber or not
  const [displayPickNumber, setDisplayPickNumber] = useState(true);

  useEffect(() => {
    if (editMode) {
      inputRef.current && inputRef.current.focus();
    }
  }, [editMode]);

  return (
    <div>
      <div className="row picklistConceptRow">
        <div className="col-12 col-lg-2">
          <Link to={`/producten/${concept.ean}/${document}`}>
            <img className={pickedImageState} src={concept.image} />
          </Link>
        </div>
        <div className="col-12 col-lg-7 verticalAlign">
          <h2 className={pickedTitleState}>
            {concept.shortTitle ? concept.shortTitle : concept.productTitle}
            {!concept.warehouseLocation && (
              <span className="mx-1 bg-red">
                <ExploreOffIcon style={{ color: "lightgrey" }} />
              </span>
            )}
          </h2>

          {/* To Do, make short title editable */}
          {/* <TextField />
          <TextField
            value={concept.shortTitle ? concept.shortTitle : concept.productTitle}
            databaseKey={'startDate'}
            editable
            collection={databaseDocumentTypes.tracecodes}
            documentId={concept._id}
            className={pickedTitleState}
          /> */}
          <div className="row">
            <div className="col-lg-6">
              <span className={conceptExtraInfo}>
                Omschrijving:{" "}
                {concept.referenceCode ? (
                  <strong>{concept.referenceCode}</strong>
                ) : (
                  <strong>Geen</strong>
                )}
              </span>

              <span className={conceptExtraInfo}>
                Huidige voorraad: {concept.ownedStock}
              </span>
            </div>
            <div className="col-lg-6">
              <span className={conceptExtraInfo}>
                <div>Ean: {concept.ean}</div>
              </span>
              <span className={conceptExtraInfo}>
                {!picked ? (
                  <div>
                    Bsku: {concept.bsku.toString().slice(0, -3)}
                    <strong>{concept.bsku.toString().slice(-3)}</strong>{" "}
                  </div>
                ) : (
                  <div>Bsku: {concept.bsku}</div>
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3 verticalAlign makeCenter">
          {displayPickNumber && (
            <div className="row">
              <div
                className="col-2 col-lg-3 verticalAlign"
                onClick={handleEdit}
              >
                {!picked && (
                  <svg
                    style={{ fill: "lightgrey" }}
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    className="picklistIcons"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                )}
              </div>

              <div className="col-8 col-lg-6">
                <div className={pickedMobile}>
                  <p onClick={handlePickedStatus}>{numberOfPickArticles}</p>
                </div>
              </div>
              {!picked && (
                <div
                  className="col-2 col-lg-3 verticalAlign"
                  onClick={handleDelete}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                    className="picklistIcons"
                  >
                    <path
                      d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                      style={{ fill: "lightgrey" }}
                    />
                  </svg>
                </div>
              )}
            </div>
          )}

          {editMode && (
            <div className="row">
              <div
                className="col-2 col-lg-3 verticalAlign"
                onClick={saveEditMode}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
                      fill="#25c781"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="col-8 col-lg-6">
                <input
                  className="replenishmentInput"
                  type="number"
                  ref={inputRef}
                  value={inputValue}
                  onChange={onChange}
                />
              </div>

              <div
                className="col-2 col-lg-3 verticalAlign"
                onClick={exitEditMode}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="backIconConepts"
                >
                  <path
                    d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                    style={{ fill: "#eb005d" }}
                  />
                </svg>
              </div>
            </div>
          )}

          {deleteMode && (
            <div className="row">
              <div className="col-2 verticalAlign" onClick={definitiveDelete}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
                      fill="#25c781"
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <div className="col-8 verticalAlign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                  className="trashIconConcept"
                >
                  <path
                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                    style={{ fill: "#eb005d" }}
                  />
                </svg>
              </div>

              <div className="col-2 verticalAlign">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="backIconConepts"
                  onClick={exitDeleteMode}
                >
                  <path
                    d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                    style={{ fill: "#eb005d" }}
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPicklist;
