import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import hostNameServer from "../../configHostname";
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function MultipleSelectCheckmarks({
  getExtendedAnnouncedShipmentInfo,
}) {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const [previousShipments, setPreviousShipments] = useState();
  const [disableSelect, setDisasbleSelect] = useState(true);

  //get shipment references
  useEffect(() => {
    async function fetchAllReplenishments(token) {
      const response = await fetch(
        `${hostNameServer}/api/products/getallreplenishmentreferences`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const json = await response.json();

      if (response.ok) {
        const sortedJson = json.sort(
          (a, b) => b.replenishmentId - a.replenishmentId,
        );

        setPreviousShipments(sortedJson);

        getAllReplenishmentReferences(token, json);

        setDisasbleSelect(false);
      }
      if (response.statusText === "Unauthorized") {
        logout();
      }
    }
    if (user) {
      const token = user.token;
      fetchAllReplenishments(token);
    }
  }, [user]);

  async function getAllReplenishmentReferences(token, referenceArray) {
    const response = await fetch(
      `${hostNameServer}/api/products/getallprocessedreplenishmentreferences`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      },
    );

    const json = await response.json();

    if (response.ok) {
      let newArr = [];

      for (const obj of referenceArray) {
        const findItem = json.find(
          (item) => item.replenishmentReference === obj.replenishmentId,
        );

        if (findItem) {
          obj.reference = "✅ " + obj.reference;
          newArr.push(obj);
        } else {
          newArr.push(obj);
        }
      }

      newArr.sort((a, b) => {
        const refA = parseInt(a.replenishmentId, 10);
        const refB = parseInt(b.replenishmentId, 10);
        return refB - refA;
      });

      setPreviousShipments(newArr);
    }
    if (response.statusText === "Unauthorized") {
      logout();
    }
  }

  const [selectedShipments, setSelectedShipments] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedShipments(typeof value === "string" ? value.split(",") : value);
    setOpen(false);
  };

  //fetch shipment info, if found in selectedShipments array
  async function fetchSpecificReplenishments(token, id) {
    const response = await fetch(
      `${hostNameServer}/api/products/getspecificreplenishment/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const json = await response.json();

    if (response.ok) {
      return json;
    }

    if (response.statusText === "Unauthorized") {
      logout();
    }
  }

  const [tempExtendedInfo, setTempExtendedInfo] = useState([]);

  useEffect(() => {
    const fetchShipmentInfo = async () => {
      if (user) {
        const token = user.token;

        const updatedTempExtendedInfo = [...tempExtendedInfo];

        for (const shipment of selectedShipments) {
          const existingInfo = updatedTempExtendedInfo.find(
            (info) => info.replenishmentId === shipment,
          );

          if (!existingInfo) {
            const info = await fetchSpecificReplenishments(token, shipment);

            updatedTempExtendedInfo.push(info);
          }
        }

        setTempExtendedInfo(updatedTempExtendedInfo);
      }
    };

    fetchShipmentInfo();
  }, [selectedShipments, user]);

  useEffect(() => {
    const filteredInfo = tempExtendedInfo.filter((info) =>
      selectedShipments.includes(info.replenishmentId),
    );
    getExtendedAnnouncedShipmentInfo(filteredInfo);
  }, [tempExtendedInfo]);

  const [open, setOpen] = useState(false);

  return (
    <div>
      <FormControl sx={{ width: 225 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">Zendingen</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          disabled={disableSelect}
          multiple
          value={selectedShipments}
          onChange={handleChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          input={<OutlinedInput label="Zendingen" />}
          renderValue={(selected) =>
            selected
              .map((replenishmentId) => {
                // Vind de bijbehorende shipment voor de referentie
                const shipment = previousShipments.find(
                  (shipment) => shipment.replenishmentId === replenishmentId,
                );
                return shipment ? shipment.reference : "";
              })
              .join(", ")
          }
          style={{ fontSize: "14px" }}
          clearable
        >
          {previousShipments &&
            previousShipments.map((shipment) => (
              <MenuItem
                key={shipment.replenishmentId}
                value={shipment.replenishmentId}
                name={shipment.reference}
              >
                <Checkbox
                  checked={
                    selectedShipments.indexOf(shipment.replenishmentId) > -1
                  }
                />
                <ListItemText
                  primary={shipment.reference}
                  primaryTypographyProps={{ fontSize: "14px" }}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
