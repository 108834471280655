import { React, useState } from 'react'
import { useLogin } from '../hooks/useLogin'
import { Link } from 'react-router-dom'

function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    

    const { login, error, isLoading } = useLogin();

    const handleSubmit = async (e) => {
        e.preventDefault()

        await login(email, password)
    }

    return (
        <form className='login' onSubmit={handleSubmit}>
            <h3>Login</h3>
            <label>Email:</label>
            <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <label>Pasword:</label>
            <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />
            <Link to="/wachtwoordvergeten" ><p className='forgotPassword'>Wachtwoord vergeten</p></Link>
            <button disabled={isLoading} className='btn-orange'>Log in</button>
            {error && <div className='error'>{error}</div>}
        </form>
    )
}

export default Login