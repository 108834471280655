import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }) {
  const [modalContent, setModalContent] = useState({
    title: "Modal Titel",
    text: "Modal Tekst",
    onOk: () => {}, // standaardfunctie, doe niets als er op OK wordt geklikt
  });

  const showModalFunction = (title, text, onOk) => {
    setModalContent({ title, text, onOk });
    const myModal = new window.bootstrap.Modal(
      document.getElementById("myModal"),
    );
    myModal.show();
  };

  return (
    <ModalContext.Provider value={showModalFunction}>
      {children}

      <div
        className="modal fade"
        id="myModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalContent.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>{modalContent.text}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Sluiten
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={modalContent.onOk}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  );
}
