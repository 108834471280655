import { React, useState, useEffect } from 'react';
import { useProductContext } from '../hooks/useProductContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuthContext } from '../hooks/useAuthContext';
import hostNameServer from '../configHostname';

function ExpectedShipmentsCard({ product }) {
  const { globalProductState, dispatch } = useProductContext();
  const [datecreated, setDate] = useState();
  const [expectedProducts, setExpectedProducts] = useState();
  const [expectedDate, setExpectedDate] = useState();
  const { user } = useAuthContext();

  useEffect(() => {
    setExpectedProducts(product.products);
  }, []);

  console.log(expectedProducts);

  useEffect(() => {
    function setCreatedDate(takendate) {
      const date = new Date(takendate);

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      setDate(formattedDate);
    }
    setCreatedDate(product.createddate);

    function setExpectedDate1(takendate) {
      const date = new Date(takendate);

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      setExpectedDate(formattedDate);
    }
    setExpectedDate1(product.expecteddate);
  }, []);

  async function handleDelete() {
    if (!user) {
      return;
    }

    const response = await fetch(`${hostNameServer}/api/products/` + product.createddate, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    const json = await response.json();
    console.log(json);

    // if (response.ok) {
    //     dispatch({ type: 'DELETE_TRACKER', payload: json })
    // }
  }

  return (
    <div className="productcard expectedCard">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 expectedContainer">
            <p className="expectedTitle">{product.name}</p>
            <span onClick={handleDelete} className="expectedDeleteButton">
              <FontAwesomeIcon icon={faTrash} />
            </span>
          </div>
          <div>
            <p className="expecteddatecreated">{datecreated}</p>
          </div>

          <div class="col-md-10">
            <p className="makeBold">Productnaam</p>
          </div>
          <div class="col-md-1 font200"></div>
          <div class="col-md-1">
            <p className="makeBold makeCenter">Aantal</p>
          </div>

          {expectedProducts &&
            expectedProducts.map((product) => (
              <div class="container-fluid expectedRow">
                <div class="row">
                  <div class="col-md-11 font200">{product.producttitle}</div>

                  <div class="col-md-1">{product.stock && <p className="makeCenter">{product.stock}</p>}</div>
                </div>
              </div>
            ))}
          <div class="col-md-12">
            <p className="expectedBox">
              <strong>Zending wordt verwacht op: {expectedDate}</strong>
            </p>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
}

export default ExpectedShipmentsCard;
