import hostNameServer from '../configHostname';

async function FetchProducts(usertoken) {
  const response = await fetch(`${hostNameServer}/api/products/getalloffers`, {
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  const json = await response.json();

  if (response.ok) {
    const offerData = JSON.parse(json.offerdata.offerdata);
    const forecast = json.salesForecast.forecasts;
    const producttitles = json.producttitles.producttitles;
    const productimages = json.productimages.productimages;
    const stocklevels = json.ownedstock.ownedstock;
    const expectedShipments = json.incomingshipments;

    const shippingArray = [];

    expectedShipments.forEach((shipment) => {
      const products = JSON.parse(shipment.products);

      products.forEach((item) => {
        const existingItem = shippingArray.find(
          (newItem) => newItem.offerId === item.offerId
        );

        if (existingItem) {
          existingItem.stock += parseInt(item.stock);
        } else {
          shippingArray.push({
            offerId: item.offerId,
            stock: parseInt(item.stock),
          });
        }
      });
    });

    const result = [];

    for (let i = 0; i < expectedShipments.length; i++) {
      const item = expectedShipments[i];

      // Parse de products-string naar een JavaScript-object
      const productData = JSON.parse(item.products);

      // Maak een nieuw object met de gewenste eigenschappen
      const newItem = {
        createddate: item.createddate,
        expecteddate: item.expecteddate,
        products: productData,
      };

      // Voeg het nieuwe object toe aan het resultaatarray
      result.push(newItem);
    }

    for (const item of offerData) {
      const offerId = item.offerId;
      const matchingShippingItem = shippingArray.find(
        (shippingItem) => shippingItem.offerId === offerId
      );

      if (matchingShippingItem) {
        item.expectedStock = matchingShippingItem.stock;
      }
    }

    // Functie om de forecast waarde op basis van het offerId te vinden
    function findForecastValueByOfferId(offerId) {
      const matchingForecast = forecast.find(
        (item) => item.offerId === offerId
      );
      return matchingForecast ? matchingForecast.forecast : null;
    }

    // Functie om de producttitle op basis van het offerId te vinden
    function findProductTitleByOfferId(offerId) {
      const matchingProductTitle = producttitles.find(
        (item) => item.offerId === offerId
      );
      return matchingProductTitle ? matchingProductTitle.producttitle : null;
    }

    // Functie om de imageUrl op basis van het offerId te vinden
    function findImageUrlByOfferId(offerId) {
      const matchingImageUrl = productimages.find(
        (item) => item.offerId === offerId
      );
      return matchingImageUrl ? matchingImageUrl.imageUrl : null;
    }

    // Functie om het stockniveau op basis van het offerId te vinden
    function findStockLevelByOfferId(offerId) {
      const matchingStockLevel = stocklevels.find(
        (item) => item.offerId === offerId
      );
      return matchingStockLevel ? matchingStockLevel.stock : null;
    }

    // Itereer over de offerData-array en voeg de forecast, producttitle en imageUrl toe
    for (const item of offerData) {
      const offerId = item.offerId;

      // Zoek de forecast waarde op basis van het offerId
      const forecastValue = findForecastValueByOfferId(offerId);

      // Voeg de forecast waarde toe aan de offerData, als deze gevonden is
      if (forecastValue !== null) {
        item.forecast = forecastValue;
      }

      // Zoek de producttitle op basis van het offerId
      const productTitle = findProductTitleByOfferId(offerId);

      // Voeg de producttitle toe aan de offerData, als deze gevonden is
      if (productTitle !== null) {
        item.producttitle = productTitle;
      }

      // Zoek de imageUrl op basis van het offerId
      const imageUrl = findImageUrlByOfferId(offerId);

      // Voeg de imageUrl toe aan de offerData, als deze gevonden is
      if (imageUrl !== null) {
        item.imageUrl = imageUrl;
      }

      // Zoek het stockniveau op basis van het offerId
      const stockLevel = findStockLevelByOfferId(offerId);

      // Voeg het stockniveau toe aan de offerData, als deze gevonden is
      if (stockLevel !== null) {
        item.stocklevel = stockLevel;
      }
    }

    const loadProductsInContext = offerData.sort(
      (a, b) => b.forecast - a.forecast
    );

    const returnValue = offerData.sort((a, b) => b.forecast - a.forecast);
    return returnValue;
  }
}

export default FetchProducts;
