import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { TrackerContextProvider } from './context/TrackerContext';
import { ProductContextProvider } from './context/ProductsContext';
import { ToastProvider } from './context/ToastContext';
import { ScrollRestoration } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <AuthContextProvider>
    <TrackerContextProvider>
      <ProductContextProvider>
        <ToastProvider>
          <App />
        </ToastProvider>
      </ProductContextProvider>
    </TrackerContextProvider>
  </AuthContextProvider>
  // </React.StrictMode>
);
