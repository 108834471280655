const categoryData = [
    //Type 0
    { category: 'Baby - Babyfoons', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 4.2, type: 0 },
    { category: 'Beauty en Verzorging - Parfum, Luxe verzorging, Make-up, Geschenksets', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 4, type: 0 },
    { category: 'Beauty en Verzorging - Erotiek m.u.v. seks- speeltjes en sets', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Beauty en Verzorging - Seks- speeltjes en sets', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 15, type: 0 },
    { category: 'Cadeaukaarten - Baby en Kind', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0, percentage: 6, type: 0 },
    { category: 'Cadeaukaarten - Belevenissen en Uitjes', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0, percentage: 10, type: 0 },
    { category: 'Cadeaukaarten - Elektronica, Lezen en leren en Goede doelen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0, percentage: 5, type: 0 },
    { category: 'Cadeaukaarten - Eten en Drinken ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0, percentage: 4, type: 0 },
    { category: 'Cadeaukaarten - Huis en tuin, Warenhuis, Beauty en Fashion', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0, percentage: 6, type: 0 },
    { category: 'Cadeaukaarten - Speelgoed en Entertainment ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0, percentage: 7, type: 0 },
    { category: 'Camera', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 4.2, type: 0 },
    { category: 'Camera - Camera Accessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Camera - Drones', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 4.2, type: 0 },
    { category: 'Computer - Accessoires ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Computer - Beamer, Beamerlamp en Beamerlens', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5.8, type: 0 },
    { category: 'Computer - Componenten', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Computer - Input Devices - A-merken****', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10, type: 0 },
    { category: 'Computer - Input Devices - Overige merken', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Computer - Laptop (m.u.v. Apple)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 3, type: 0 },
    { category: 'Computer - Laptop (Apple)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 2.1, type: 0 },
    { category: 'Computer - Desktop en Monitor', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 4.2, type: 0 },
    { category: 'Computer - Multimedia Devices ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8.3, type: 0 },
    { category: 'Computer - Network Equipment', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Computer - Printer Hardware', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Computer - Scanners', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5.8, type: 0 },
    { category: 'Computer - Software', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Computer - Storage ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 4, type: 0 },
    { category: 'Computer - Supplies - Originele merken *****', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 4.2, type: 0 },
    { category: 'Computer - Supplies - Overige merken *****', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Computer - USB sticks', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Computer - Geheugenkaarten', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8, type: 0 },
    { category: 'Computer - Voedingsadapter', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Dier - Dierbenodigdheden en Ruitersport', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Dier - Diervoeding', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 6, type: 0 },
    { category: 'Dier - Kattenbakvulling', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 6, type: 0 },
    { category: 'Entertainment - Film (zowel nieuw als tweedehands)***', underTen: 1.2, underTwenty: 1.4, aboveTwenty: 1.85, percentage: 9, type: 0 },
    { category: 'Entertainment - Game seats', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8, type: 0 },
    { category: 'Entertainment - Games Accessoires (m.u.v. Controllers)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8, type: 0 },
    { category: 'Entertainment - Games Accessoires - Controllers', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Entertainment - Games Consoles', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 5, type: 0 },
    { category: 'Entertainment - Games Software Digital***', underTen: 1.2, underTwenty: 1.4, aboveTwenty: 1.85, percentage: 9, type: 0 },
    { category: 'Entertainment - Games Software Physical (zowel nieuw als tweedehands)***', underTen: 1.2, underTwenty: 1.4, aboveTwenty: 1.85, percentage: 9, type: 0 },
    { category: 'Entertainment - Muziek (zowel nieuw als tweedehands)***', underTen: 1.2, underTwenty: 1.4, aboveTwenty: 1.85, percentage: 9, type: 0 },
    { category: 'Eten en Drinken - Thuisbrouwpakket', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Huishoudelijke apparaten - Accessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Huishoudelijke apparaten - Groot Huishoudelijk ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 5, type: 0 },
    { category: 'Huishoudelijke apparaten - Heat en Air', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10, type: 0 },
    { category: 'Huishoudelijke apparaten - Replacement Parts', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Klussen - Auto en Motor', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Klussen - Badkamermeubelen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 10, type: 0 },
    { category: 'Klussen - Elektrisch gereedschap', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 4.2, type: 0 },
    { category: 'Klussen - Elektrisch gereedschap accessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0, percentage: 12.4, type: 0 },
    { category: 'Klussen - Gereedschap, verf en behang', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Klussen - Witte klusverf (emmers) ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Klussen - Groot Sanitair', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 7, type: 0 },
    { category: 'Klussen - Opbergen en Verhuizen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 10.8, type: 0 },
    { category: 'Klussen - Sanitair en Veilig Wonen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Koken, Tafelen en Huishouden', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Koken, Tafelen en Huishouden - Barbecues', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10.8, type: 0 },
    { category: 'Lezen en Leren - Boeken (zowel nieuw als tweedehands)***', underTen: 1.2, underTwenty: 1.4, aboveTwenty: 1.85, percentage: 12.4, type: 0 },
    { category: 'Lezen en Leren - Ereader Accessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Lezen en Leren - Ereaders', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 5, type: 0 },
    { category: 'Mode en Sport - Horloges en Zonnebrillen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 13.3, type: 0 },
    { category: 'Mode - Sieraden', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 20.7, type: 0 },
    { category: 'Mode - Tassen, Reisbagage en Modeaccessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 13.3, type: 0 },
    { category: 'School en Kantoor ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'School en Kantoor - Elektrische kantoorartikelen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'School en Kantoor - Puzzelboeken en tijdschriften', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Speelgoed', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Speelgoed - Blokken en Vormen (m.u.v. DUPLO, LEGO, Playmobil)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Speelgoed - Blokken en Vormen (DUPLO, LEGO, Playmobil)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 7, type: 0 },
    { category: 'Speelgoed - Radiografisch voertuig', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8.3, type: 0 },
    { category: 'Speelgoed - Speelhuis, Trampoline en Zwembad', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8.3, type: 0 },
    { category: 'Sport Hardwaren - Duikcomputer, Duikhorloge en Duiklamp', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 7, type: 0 },
    { category: 'Sport Hardwaren - Duiken en Snorkelen overig', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10.8, type: 0 },
    { category: 'Sport Hardwaren - Elektrische fiets', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 9, type: 0 },
    { category: 'Sport Hardwaren - Fiets m.u.v. Elektrische fiets', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Sport Hardwaren - Fiets - Fietsendragers', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 9, type: 0 },
    { category: 'Sport Hardwaren - Fitness ', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Sport Hardwaren - Hartslagmeter', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Sport Hardwaren - Hengelsport en Outdooruitusting', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Sport Hardwaren - Kamperen en Slapen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10.8, type: 0 },
    { category: 'Sport Hardwaren - Sportaccessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10.8, type: 0 },
    { category: 'Sport Hardwaren - Sportbescherming- en materialen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Sport Hardwaren - Stappenteller en Stopwatch', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10.8, type: 0 },
    { category: 'Sport Hardwaren - Watersport en Bootuitrusting', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8.3, type: 0 },
    { category: 'Sport Hardwaren - Wintersport', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Telefonie en Tablets (m.u.v. Apple)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 5, type: 0 },
    { category: 'Telefonie en Tablets - Apple', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 3, type: 0 },
    { category: 'Telefonie en Tablets - Accessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Tuin en Kerst - Elektrisch tuingereedschap', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 6.2, type: 0 },
    { category: 'Tuin en Kerst - Hogedrukreinigers en Robotmaaiers', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 4.2, type: 0 },
    { category: 'Tuin en Kerst - Kerstbomen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Tuin en Kerst - Tuin en Kerst', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'TV en Audio - Accessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'TV en Audio - Activitytracker', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'TV en Audio - Home Entertainment', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5.8, type: 0 },
    { category: 'TV en Audio - Navigatie', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 5, type: 0 },
    { category: 'TV en Audio - Personal Audio (m.u.v. In Ear oordoppen)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5.8, type: 0 },
    { category: 'TV en Audio - Personal Audio - In Ear oordoppen (m.u.v. A-merk)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'TV en Audio - Personal Audio - In Ear oordoppen A-merk****', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5.8, type: 0 },
    { category: 'TV en Audio - Smartwatch', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 5, type: 0 },
    { category: 'TV en Audio - Sporthorloges', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 7, type: 0 },
    { category: 'TV en Audio - Televisie', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 4.2, type: 0 },
    { category: 'Wonen - Lichtbron', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 5, type: 0 },
    { category: 'Wonen - Lijsten en Posters', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 10, type: 0 },
    { category: 'Wonen - Meubelen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 2.99, percentage: 10.8, type: 0 },
    { category: 'Wonen - Sprei, Deken, Laken, Kussensloop en Molton', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 14.1, type: 0 },
    { category: 'Wonen - Textiel', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Wonen - Verlichting (m.u.v. Slimme verlichting)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    { category: 'Wonen - Slimme verlichting', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 8, type: 0 },
    { category: 'Wonen - Woondecoratie en -accessoires', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentage: 12.4, type: 0 },
    //type 1
    { category: 'Baby (m.u.v. Babyfoons, Baby Hardwaren XL en Babykleding)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 12.4, type: 1 },
    { category: 'Baby (m.u.v. Babyfoons, Baby Hardwaren XL en Babykleding)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 12.4, type: 1 },
    { category: 'Baby - Hardwaren XL  (o.a. autostoeltje, veiligheidshekje en kinderwagen)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 8, type: 1 },
    { category: 'Beauty en Verzorging - Dagelijkse Verzorging', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 10.8, type: 1 },
    { category: 'Beauty en Verzorging - Dagelijkse Verzorging - Contactlenzen en Lenzenvloeistof', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 7, type: 1 },
    { category: 'Beauty en Verzorging - Gezondheid (m.u.v. Erotiek)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 10.8, type: 1 },
    { category: 'Beauty en Verzorging - Huishoudpapier, Schoonmaakmiddelen en (Vaat)wasmiddelen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 10.8, type: 1 },
    { category: 'Eten en Drinken (m.u.v. Thuisbrouwpakket)', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5.5, percentageAboveTwenty: 12.5, type: 1 },
    { category: 'Koken, Tafelen en Huishouden - Kookgerei', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 5, percentageAboveTwenty: 12.4, type: 1 },
    { category: 'Tuin en Kerst - Verse Boeketten', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 8, percentageAboveTwenty: 12.4, type: 1 },
    //type 2
    { category: 'Huishoudelijke apparaten - Household Appliances', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderHundred: 8, percentageAboveHundred: 6, type: 2 },
    { category: 'Huishoudelijke apparaten - Kitchen Machines', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderHundred: 8, percentageAboveHundred: 6, type: 2 },
    { category: 'Huishoudelijke apparaten - Personal Care', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderHundred: 8, percentageAboveHundred: 6, type: 2 },
    { category: 'TV en Audio - Muziekinstrumenten', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderHundred: 12.4, percentageAboveHundred: 6, type: 2 },
    //type 3
    { category: 'Mode en Sport - Baby- en Kinderkleding', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 4, percentageTwentyFifty: 10, percentageAboveFifty: 13, type: 3 },
    { category: 'Mode en Sport - Dameskleding', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 4, percentageTwentyFifty: 4, percentageAboveFifty: 13, type: 3 },
    { category: 'Mode en Sport - Herenkleding', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 4, percentageTwentyFifty: 12, percentageAboveFifty: 13, type: 3 },
    { category: 'Mode en Sport - Ondergoed en Zwemkleding', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 4, percentageTwentyFifty: 12, percentageAboveFifty: 13, type: 3 },
    { category: 'Mode en Sport - Schoenen', underTen: 0.2, underTwenty: 0.4, aboveTwenty: 0.85, percentageUnderTwenty: 4, percentageTwentyFifty: 10, percentageAboveFifty: 13, type: 3 },
]

module.exports = categoryData