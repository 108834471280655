import { useState, useRef, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import { useUpdateDatabaseDocument } from '../../controllers/databaseControllers/updateDocument';

const TextField = (props) => {
  const [editable, setEditable] = useState(props.editable);
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [inputValue, setInputValue] = useState(props.value);
  const [link, setLink] = useState(props.link);
  const [linkUrl, setLinkUrl] = useState(props.linkUrl);
  const [type, setType] = useState(props.type || 'text');
  const updateDocument = useUpdateDatabaseDocument();

  function handleEditClick() {
    setEditMode(true);
    console.log(inputRef);
  }

  function handleDoneClick() {
    setEditMode(false);

    const DBkey = props.databaseKey;

    const data = {
      collection: props.collection,
      documentId: props.documentId,
      fields: {
        [DBkey]: inputValue,
      },
      successMessage: 'Veld bijgewerkt',
    };

    updateDocument(data);
  }

  function handleChange(e) {
    setInputValue(e.target.value);
  }

  useEffect(() => {
    if (editMode) {
      inputRef.current && inputRef.current.focus();
    }
  }, [editMode]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEditMode(false);
      }
    }

    if (editMode) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMode]);

  function handleKeyDown(e) {
    if (e.key === 'Enter' && editMode) {
      handleDoneClick();
    }
  }

  return (
    <div className={props.className}>
      {!editMode ? (
        <div className="textFieldClass">
          {!link ? (
            <p className="textFieldText">{inputValue}</p>
          ) : (
            <a className="textFieldText" target="blank" href={linkUrl}>
              {inputValue}
            </a>
          )}
          {editable && (
            <IconButton>
              <EditIcon className="editIcon" onClick={handleEditClick} />
            </IconButton>
          )}
        </div>
      ) : (
        <div className="textFieldClass" ref={containerRef}>
          <input
            value={inputValue}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            onChange={handleChange}
            spellcheck="false"
            className="textFieldInputField"
            type={type}
          />
          <IconButton>
            <DoneIcon className="doneIcon" onClick={handleDoneClick} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default TextField;
