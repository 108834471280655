import React from 'react'
import CurrencyFormat from 'react-currency-format';

function FormInput(props) {
    return (
        <div className='input-group mb-3'>
            <span className="input-group-text label">{props.icon} {props.text}</span>

            {/* <input
                type="Number"
                placeholder={props.placeholder}
                value={props.value}
                className={props.className}
                onChange={props.onChange}
                readOnly={props.readOnly}
                name={props.name}
            /> */}
            <CurrencyFormat
                value={props.value}
                displayType={'input'}
                decimalScale={props.decimalScale}
                prefix={props.prefix}
                className={props.className}
                allowNegative={false}
                decimalSeparator={","}
                placeholder={props.placeholder}
                onChange={props.onChange}
                name={props.name}
                suffix={props.suffix}
                onValueChange={props.onValueChange}
            />
        </div>
    )
}

export default FormInput