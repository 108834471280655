import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { useEffect } from 'react';
import { useState } from 'react';

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const handleClick = () => {
    logout();
  };

  function handleMenuClick() {
    document.querySelector('.navbar-collapse').classList.remove('show');
  }

  return (
    <nav class="navbar-custom navbar navbar-expand-lg navbar-dark ">
      {/* <Link to="/winstcalculator"></Link> */}

      <img src="https://www.businessbaas.nl/wp-content/uploads/2023/02/Logo-wit-1.png" width="225" alt="" />
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <a class="navbar-brand" href="#"></a>
          {user && (
            <li class="nav-item">
              <Link class="nav-link nav-link-custom" to="/winstcalculator">
                Winst Calculator
              </Link>
            </li>
          )}

          {user && (
            <li class="nav-item hideOnMobile">
              <Link class="nav-link nav-link-custom" to="/producten">
                Producten
              </Link>
            </li>
          )}

          <li className="nav-item dropdown hideOnDesktop">
            <a
              className="nav-link nav-link-custom dropdown-toggle"
              href="#"
              id="productenDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Producten
            </a>
            <div className="dropdown-menu" aria-labelledby="productenDropdown">
              <Link className="dropdown-item" to="/binnenkomendezendingen" onClick={handleMenuClick}>
                Binnenkomende zendingen
              </Link>
              <Link className="dropdown-item" to="/tracecodes" onClick={handleMenuClick}>
                Tracecodes
              </Link>
              <Link className="dropdown-item" to="/producten" onClick={handleMenuClick}>
                Producten
              </Link>
              <Link className="dropdown-item" to="/lvb" onClick={handleMenuClick}>
                LVB zending
              </Link>
              <Link className="dropdown-item" to="/picklist" onClick={handleMenuClick}>
                Paklijst
              </Link>
              <Link className="dropdown-item" to="/stockmanagement" onClick={handleMenuClick}>
                Stock Management
              </Link>
            </div>
          </li>

          {user && (
            <li class="nav-item">
              <Link onClick={handleClick} class="nav-link nav-link-custom">
                Logout
              </Link>
            </li>
          )}

          {/* <li class="nav-item">
            <button >Log out</button>
          </li> */}
          {/* ----> Aanzetten op Login en Sign up weer te geven. */}
          {/* {!user &&
            <li class="nav-item">
              <Link class="nav-link nav-link-custom" to="/login">Login</Link>
            </li>}
          {!user &&
            <li class="nav-item">
              <Link class="nav-link nav-link-custom" to="/signup">Sign up</Link>
            </li>} */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
