import { React, useState, useRef, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import af0 from "../assets/0.png";
import af1 from "../assets/1.png";
import af2 from "../assets/2.png";
import af3 from "../assets/3.png";
import af4 from "../assets/4.png";
import af5 from "../assets/5.png";
import Badge from "@mui/material/Badge";

const LVBcard = ({ product, onStockChange }) => {
  // Initial state voor het stockNumber en bewerkingsmodus
  const [stockNumber, setStockNumber] = useState(0);
  const [stockDays, setStockDays] = useState();
  const [requiredStock, setRequiredStock] = useState(null);
  const [afterReplenishment, setAfterReplenishment] = useState(0);
  const [currentlyNoStock, setCurrentlyNoStock] = useState(false);

  //number of current stock days
  // useEffect(() => {
  //   if (product.LVBstock > 0 && product.forecast > 0) {
  //     setStockDays(
  //       Math.round(
  //         ((product.quantityAnnounced ? Number(product.quantityAnnounced) : 0) +
  //           Number(product.LVBstock)) /
  //           (Number(product.forecast) / 28),
  //       ),
  //     );
  //   } else if (Number(product.forecast) === 0 && product.LVBstock > 0) {
  //     setStockDays("♾️");
  //   } else {
  //     setStockDays(0);
  //   }
  // }, [product]);

  // useEffect(() => {
  //   let totalStock = Number(product.LVBstock);
  //
  //   if (product.quantityAnnounced) {
  //     totalStock += Number(product.quantityAnnounced);
  //   }
  //
  //   if (totalStock > 0 && product.forecast > 0) {
  //     setStockDays(Math.round(totalStock / (Number(product.forecast) / 28)));
  //   } else if (Number(product.forecast) === 0 && totalStock > 0) {
  //     setStockDays("♾️");
  //   } else {
  //     setStockDays(0);
  //   }
  // }, [product, product.quantityAnnounced]);

  //handle change of input
  const handleStockChange = (e) => {
    const newValue = +e.target.value;
    if (!isNaN(newValue)) {
      // Controleer of newValue een getal is
      setRequiredStock(newValue === 0 ? null : newValue);
      onStockChange(product.offerId, newValue === 0 ? null : newValue);
    }
  };

  useEffect(() => {
    onStockChange(product.offerId, requiredStock);
  }, [requiredStock]);

  //disable input when stock is not available
  useEffect(() => {
    if (product.ownedStock === 0) {
      setCurrentlyNoStock(true);
    }
  }, [product]);

  // //update number of stockdays after replenishment
  // useEffect(() => {
  //   if (Number(product.forecast) !== 0) {
  //     setAfterReplenishment(
  //       Math.round(
  //         (Number(product.LVBstock) + Number(requiredStock)) /
  //           (Number(product.forecast) / 28),
  //       ) || 0,
  //     );
  //   } else if (Number(product.forecast) === 0 && product.LVBstock > 0) {
  //     setAfterReplenishment("♾️");
  //   } else {
  //     setAfterReplenishment(0);
  //   }
  // }, [requiredStock]);

  const calculateStockDays = (
    stock,
    forecast,
    quantityAnnounced = 0,
    requiredStock = 0,
  ) => {
    let totalStock = stock + requiredStock;

    if (product.quantityAnnounced) {
      totalStock += Number(product.quantityAnnounced);
    }

    if (totalStock > 0 && product.forecast > 0) {
      return Math.round(totalStock / (Number(product.forecast) / 28));
    } else if (Number(product.forecast) === 0 && totalStock > 0) {
      return "♾️";
    } else {
      return 0;
    }
  };

  //...

  useEffect(() => {
    setStockDays(
      calculateStockDays(
        Number(product.LVBstock),
        Number(product.forecast),
        Number(product.quantityAnnounced),
      ),
    );
  }, [product, product.quantityAnnounced]);

  //update number of stockdays after replenishment
  useEffect(() => {
    setAfterReplenishment(
      calculateStockDays(
        Number(product.LVBstock),
        Number(product.forecast),
        Number(product.quantityAnnounced),
        Number(requiredStock),
      ),
    );
  }, [product, requiredStock, product.quantityAnnounced]);

  return (
    <div className="productcard">
      <div className="row">
        <div className="col-lg-2">
          <img src={product.image} alt="" />
        </div>
        <div className="col-lg-3 TDmain">
          {/* <p className='trackerDetailsRemaining'><strong>{tracker.brand}</strong></p> */}
          <p
            className="ProductDetailsTitle m-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={product.productTitle}
          >
            {product.shortTitle ? product.shortTitle : product.productTitle}
          </p>
          <div className="eanIconContainer d-flex ">
            <p className="productDetailsRemaining m-0">EAN: {product.ean}</p>{" "}
            <svg
              width="13"
              height="13"
              s
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ms-1"
              role="button"
              onClick={() =>
                window.open(
                  `https://partner.bol.com/sdd/offeranalysis/stock/?product-id=${product.productId}`,
                  "_blank",
                )
              }
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 4C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44772 22 3V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68342 15.0976 9.29289 14.7071C8.90237 14.3166 8.90237 13.6834 9.29289 13.2929L18.5858 4H15ZM5 7C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16C16.2652 20 16.5196 19.8946 16.7071 19.7071C16.8946 19.5196 17 19.2652 17 19V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V19C19 19.7957 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7957 22 16 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V8C2 7.20435 2.31607 6.44129 2.87868 5.87868C3.44129 5.31607 4.20435 5 5 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H5Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <p className="productDetailsRemaining mb-1">Bsku: {product.bsku}</p>
        </div>

        <div className="col-lg-1 col-4">
          <div className="stockChild">
            <p className="lvbNumbers">{product.ownedStock}</p>
          </div>
        </div>
        <div className="col-lg-1 col-4 d-flex align-items-center justify-content-center ">
          <div className="lvbStockNumber ">
            <p className="m-0">{product.LVBstock}</p>
            {product.quantityAnnounced > 0 && (
              <p className="m-0 quantityAnnounced">
                <strong>
                  voorgemeld:
                  {product.quantityAnnounced}
                </strong>
              </p>
            )}
          </div>
        </div>
        <div className="col-lg-1 col-4">
          <div className="">
            <p className="lvbNumbers">{Math.round(product.forecast)}</p>
          </div>
        </div>
        <div className="col-lg-1 col-4 d-flex flex-column align-items-center justify-content-center">
          <p
            className={`m-0 ${product.quantityAnnounced ? "text-primary" : ""}`}
          >
            {stockDays}
          </p>
          <div>
            {stockDays === 0 && (
              <img src={af0} alt="Afbeelding" className="conditionImage1" />
            )}
            {stockDays > 0 && stockDays <= 7 && (
              <img
                src={af1}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {stockDays > 7 && stockDays <= 14 && (
              <img
                src={af2}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {stockDays >= 15 && stockDays <= 21 && (
              <img
                src={af3}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {stockDays > 21 && stockDays <= 28 && (
              <img
                src={af4}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {stockDays > 28 && (
              <img
                src={af5}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {stockDays === "♾️" && (
              <img
                src={af5}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
          </div>
        </div>
        <div className="col-lg-1 centerInputLVB col-4">
          {!currentlyNoStock && (
            <div class="form-group inputNewStock">
              <input
                type="number"
                class="form-control stockNumberInput"
                value={requiredStock}
                onChange={handleStockChange}
              />
            </div>
          )}
        </div>
        <div className="col-lg-1 col-4 d-flex flex-column align-items-center justify-content-center">
          <div>
            <p
              className={`m-0 ${
                product.quantityAnnounced ? "text-primary" : ""
              }`}
            >
              {afterReplenishment}
            </p>
          </div>
          <div>
            {afterReplenishment === 0 && (
              <img
                src={af0}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {afterReplenishment > 0 && afterReplenishment <= 7 && (
              <img
                src={af1}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {afterReplenishment > 7 && afterReplenishment <= 14 && (
              <img
                src={af2}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {afterReplenishment >= 15 && afterReplenishment <= 21 && (
              <img
                src={af3}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {afterReplenishment > 21 && afterReplenishment <= 28 && (
              <img
                src={af4}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {afterReplenishment > 28 && (
              <img
                src={af5}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
            {stockDays === "♾️" && (
              <img
                src={af5}
                alt="Afbeelding"
                className="conditionImageReplenishment"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LVBcard;
