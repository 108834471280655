import React from 'react'
import { useTrackerContext } from '../hooks/useTrackerContext';
import { useAuthContext } from '../hooks/useAuthContext';
import { Link } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

function TrackerDetails({ tracker }) {
  const { trackers, dispatch } = useTrackerContext();
  const { user } = useAuthContext();

  async function handleClick() {
    if (!user) {
      return
    }

    const response = await fetch('/api/tracker/' + tracker.ean, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })

    const json = await response.json()
    console.log(json)

    if (response.ok) {
      dispatch({ type: 'DELETE_TRACKER', payload: json })
    }
  }



  return (
    <div className='pricetrackercards'>
      <div className='row'>
        <div className='col-lg-2'>
          <img src={tracker.image} alt="" />
        </div>
        <div className='col-lg-6 TDmain'>
          <p className='trackerDetailsRemaining'><strong>{tracker.brand}</strong></p>
          <p className='trackerDetailsTitle'>{tracker.title}</p>
          <p className='trackerDetailsRemaining'>EAN: {tracker.ean}</p>
        </div>
        <div className='col-lg-2 TDprice'>
          <p className='TDpriceP'><CurrencyFormat
            value={tracker.price}
            displayType={'text'}
            decimalScale={2}
            prefix={"€"}
            allowNegative={false}
            decimalSeparator={","}
            placeholder={"€0,00"}
            fixedDecimalScale={true}
          /></p>
        </div>
        <div className='col-lg-2 TDtotracker'>
          <Link to={`/pricetracker/${tracker.ean}`} className="btn btn-primary btn-sm trackerButton">Naar tracker</Link>

          <span onClick={handleClick} className='TDdelete'><FontAwesomeIcon icon={faTrash} /></span>
        </div>
      </div>
    </div>
  )
}

export default TrackerDetails