import { React, useState } from 'react'
import { usePasswordReset } from '../hooks/useReset'


function PasswordReset() {

    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    


    const { passReset, error, isLoading, succeed } = usePasswordReset();

    const handleSubmit = async (e) => {
        e.preventDefault()

        await passReset(password1, password2)
        setPassword1("")
        setPassword2("")
    }

    return (
        <form className='login' onSubmit={handleSubmit}>
            <h3>Wachtwoord herstellen</h3>
            <label>Wachtwoord:</label>
            <input
                type="password"
                onChange={(e) => setPassword1(e.target.value)}
                value={password1}
            />
            <label>Herhaal je wachtwoord:</label>
            <input
                type="password"
                onChange={(e) => setPassword2(e.target.value)}
                value={password2}
            />
            <button disabled={isLoading}>Log in</button>
            {error && <div className='error'>{error}</div>}
            {succeed && <div className='error'>Je wachtwoord is bijgewerkt.</div>}
        </form>
    )
}

export default PasswordReset