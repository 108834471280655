import { createContext, useReducer } from 'react'

export const TrackerContext = createContext();

export const TrackerReducer = (state, action) => {
    switch (action.type) {
        case "LOAD_TRACKERS":
            return {
                trackers: action.payload
            }
        case 'DELETE_TRACKER':
            return {
                trackers: state.trackers.filter((w) => w.ean !== action.payload.primaryproduct)
                
            }
        default:
            return state
    }
}

export const TrackerContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(TrackerReducer, {
        trackers: null
    })

    console.log("Tracker state", state)

    return (
        <TrackerContext.Provider value={{ ...state, dispatch }}>
            {children}
        </TrackerContext.Provider>
    )
}