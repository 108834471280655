import { React, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     // Sla de huidige URL op in de sessieopslag
    //     sessionStorage.setItem("lastUrl", window.location.pathname);
    // }, []);

    useEffect(() => {
        // Wanneer de component gemonteerd is, haal de laatste URL op uit de sessieopslag
        const lastUrl = sessionStorage.getItem("lastUrl");
        if (lastUrl) {
            // Navigeer naar de laatste URL
            navigate(lastUrl);
            console.log("url: " + lastUrl)
        } else {
            navigate("/producten")
        }
    }, []);

    

    return (
        <div>
            <h1>hi</h1>
        </div>


    )
}

export default Home