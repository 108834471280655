import { createContext, useReducer } from 'react'

export const ProductContext = createContext();

export const ProductReducer = (state, action) => {
    switch (action.type) {
        case "LOAD_PRODUCTS":
            return {
                globalProductState: action.payload
            }
        case 'DELETE_TRACKER':
            return {
                globalProductState: state.trackers.filter((w) => w.ean !== action.payload.primaryproduct)
                
            }
        default:
            return state
    }
}

export const ProductContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ProductReducer, {
        globalProductState: null
    })

    console.log("Product state", state)

    return (
        <ProductContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ProductContext.Provider>
    )
}