import { React, useState } from 'react'
import { useAuthContext } from "../hooks/useAuthContext"
import { useNavigate } from "react-router-dom"

function AddTracker() {
    const [ean, setEan] = useState("")
    // const [eanCom, setEanCom] = useState("")
    const { user } = useAuthContext()
    const navigate = useNavigate();
    const [error, setError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/tracker/newtracker', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ ean, })
        })
        const json = await response.json()
        console.log(json)
        
        if (!response.ok) {
            console.log("Tracking is niet gelukt")
            setError(json.error)
        }
        if (response.ok) {
            console.log("product wordt nu getrackt")
            navigate("/pricetracker")
        }

    }

    // const handleSubmitCom = async (e) => {
    //     e.preventDefault()

    //     const response = await fetch('/api/tracker/newcom', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${user.token}`
    //         },
    //         body: JSON.stringify({ eanCom })
    //     })
    //     const json = await response.json()

    //     if (!response.ok) {
    //         console.log("Tracking is niet gelukt")
    //     }
    //     if (response.ok) {
    //         console.log("product wordt nu getrackt")

    //     }
    // }




    return (
        <div>

            <div className="home">
                <form onSubmit={handleSubmit} >
                    <label>Welk product wil je tracken?</label>
                    <input
                        type="text"
                        onChange={(e) => setEan(e.target.value)}
                        value={ean}
                    />
                    <button>Track product</button>
                    {error && <div className='error'>{error}</div>}

                </form>
            </div >

            {/* <div className="home">
                <form onSubmit={handleSubmitCom} >
                    <label>Welke concurrent wil je tracken?</label>
                    <input
                        type="text"
                        onChange={(e) => setEanCom(e.target.value)}
                        value={eanCom}
                    />
                    <button>Track Concurrent</button>

                </form> */}
            {/* </div > */}




        </div>
    )
}

export default AddTracker