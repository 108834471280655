import { React, useState, useEffect } from 'react'
import Select from 'react-select';
import categoryData from '../CategoryData';



function DropDownCategory(props) {
    const [category, setCategory] = useState({})

    function handleCategoryChange(event) {

        const findCat = categoryData.find(element => element.category === event.label);
        setCategory(findCat)

    }

    useEffect(() => {
        props.updateCommission(category)
    }, [category])

    const colourOptions = [
        { value: "Baby - Babyfoons", label: "Baby - Babyfoons" },
        { value: "Baby (m.u.v. Babyfoons, Baby Hardwaren XL en Babykleding)", label: "Baby (m.u.v. Babyfoons, Baby Hardwaren XL en Babykleding)" },
        { value: "Baby - Hardwaren XL  (o.a. autostoeltje, veiligheidshekje en kinderwagen)", label: "Baby - Hardwaren XL  (o.a. autostoeltje, veiligheidshekje en kinderwagen)" },
        { value: "Beauty en Verzorging - Dagelijkse Verzorging", label: "Beauty en Verzorging - Dagelijkse Verzorging" },
        { value: "Beauty en Verzorging - Dagelijkse Verzorging - Contactlenzen en Lenzenvloeistof", label: "Beauty en Verzorging - Dagelijkse Verzorging - Contactlenzen en Lenzenvloeistof" },
        { value: "Beauty en Verzorging - Gezondheid (m.u.v. Erotiek)", label: "Beauty en Verzorging - Gezondheid (m.u.v. Erotiek)" },
        { value: "Beauty en Verzorging - Huishoudpapier, Schoonmaakmiddelen en (Vaat)wasmiddelen", label: "Beauty en Verzorging - Huishoudpapier, Schoonmaakmiddelen en (Vaat)wasmiddelen" },
        { value: "Beauty en Verzorging - Parfum, Luxe verzorging, Make-up, Geschenksets", label: "Beauty en Verzorging - Parfum, Luxe verzorging, Make-up, Geschenksets" },
        { value: "Beauty en Verzorging - Erotiek m.u.v. seks- speeltjes en sets", label: "Beauty en Verzorging - Erotiek m.u.v. seks- speeltjes en sets" },
        { value: "Beauty en Verzorging - Seks- speeltjes en sets", label: "Beauty en Verzorging - Seks- speeltjes en sets" },
        { value: "Cadeaukaarten - Baby en Kind", label: "Cadeaukaarten - Baby en Kind" },
        { value: "Cadeaukaarten - Belevenissen en Uitjes", label: "Cadeaukaarten - Belevenissen en Uitjes" },
        { value: "Cadeaukaarten - Elektronica, Lezen en leren en Goede doelen", label: "Cadeaukaarten - Elektronica, Lezen en leren en Goede doelen" },
        { value: "Cadeaukaarten - Eten en Drinken ", label: "Cadeaukaarten - Eten en Drinken " },
        { value: "Cadeaukaarten - Huis en tuin, Warenhuis, Beauty en Fashion", label: "Cadeaukaarten - Huis en tuin, Warenhuis, Beauty en Fashion" },
        { value: "Cadeaukaarten - Speelgoed en Entertainment ", label: "Cadeaukaarten - Speelgoed en Entertainment " },
        { value: "Camera", label: "Camera" },
        { value: "Camera - Camera Accessoires", label: "Camera - Camera Accessoires" },
        { value: "Camera - Drones", label: "Camera - Drones" },
        { value: "Computer - Accessoires ", label: "Computer - Accessoires " },
        { value: "Computer - Beamer, Beamerlamp en Beamerlens", label: "Computer - Beamer, Beamerlamp en Beamerlens" },
        { value: "Computer - Componenten", label: "Computer - Componenten" },
        { value: "Computer - Input Devices - A-merken****", label: "Computer - Input Devices - A-merken****" },
        { value: "Computer - Input Devices - Overige merken", label: "Computer - Input Devices - Overige merken" },
        { value: "Computer - Laptop (m.u.v. Apple)", label: "Computer - Laptop (m.u.v. Apple)" },
        { value: "Computer - Laptop (Apple)", label: "Computer - Laptop (Apple)" },
        { value: "Computer - Desktop en Monitor", label: "Computer - Desktop en Monitor" },
        { value: "Computer - Multimedia Devices ", label: "Computer - Multimedia Devices " },
        { value: "Computer - Network Equipment", label: "Computer - Network Equipment" },
        { value: "Computer - Printer Hardware", label: "Computer - Printer Hardware" },
        { value: "Computer - Scanners", label: "Computer - Scanners" },
        { value: "Computer - Software", label: "Computer - Software" },
        { value: "Computer - Storage ", label: "Computer - Storage " },
        { value: "Computer - Supplies - Originele merken *****", label: "Computer - Supplies - Originele merken *****" },
        { value: "Computer - Supplies - Overige merken *****", label: "Computer - Supplies - Overige merken *****" },
        { value: "Computer - USB sticks", label: "Computer - USB sticks" },
        { value: "Computer - Geheugenkaarten", label: "Computer - Geheugenkaarten" },
        { value: "Computer - Voedingsadapter", label: "Computer - Voedingsadapter" },
        { value: "Dier - Dierbenodigdheden en Ruitersport", label: "Dier - Dierbenodigdheden en Ruitersport" },
        { value: "Dier - Diervoeding", label: "Dier - Diervoeding" },
        { value: "Dier - Kattenbakvulling", label: "Dier - Kattenbakvulling" },
        { value: "Entertainment - Film (zowel nieuw als tweedehands)***", label: "Entertainment - Film (zowel nieuw als tweedehands)***" },
        { value: "Entertainment - Game seats", label: "Entertainment - Game seats" },
        { value: "Entertainment - Games Accessoires (m.u.v. Controllers)", label: "Entertainment - Games Accessoires (m.u.v. Controllers)" },
        { value: "Entertainment - Games Accessoires - Controllers", label: "Entertainment - Games Accessoires - Controllers" },
        { value: "Entertainment - Games Consoles", label: "Entertainment - Games Consoles" },
        { value: "Entertainment - Games Software Digital***", label: "Entertainment - Games Software Digital***" },
        { value: "Entertainment - Games Software Physical (zowel nieuw als tweedehands)***", label: "Entertainment - Games Software Physical (zowel nieuw als tweedehands)***" },
        { value: "Entertainment - Muziek (zowel nieuw als tweedehands)***", label: "Entertainment - Muziek (zowel nieuw als tweedehands)***" },
        { value: "Eten en Drinken (m.u.v. Thuisbrouwpakket)", label: "Eten en Drinken (m.u.v. Thuisbrouwpakket)" },
        { value: "Eten en Drinken - Thuisbrouwpakket", label: "Eten en Drinken - Thuisbrouwpakket" },
        { value: "Huishoudelijke apparaten - Accessoires", label: "Huishoudelijke apparaten - Accessoires" },
        { value: "Huishoudelijke apparaten - Groot Huishoudelijk ", label: "Huishoudelijke apparaten - Groot Huishoudelijk " },
        { value: "Huishoudelijke apparaten - Heat en Air", label: "Huishoudelijke apparaten - Heat en Air" },
        { value: "Huishoudelijke apparaten - Household Appliances", label: "Huishoudelijke apparaten - Household Appliances" },
        { value: "Huishoudelijke apparaten - Kitchen Machines", label: "Huishoudelijke apparaten - Kitchen Machines" },
        { value: "Huishoudelijke apparaten - Personal Care", label: "Huishoudelijke apparaten - Personal Care" },
        { value: "Huishoudelijke apparaten - Replacement Parts", label: "Huishoudelijke apparaten - Replacement Parts" },
        { value: "Klussen - Auto en Motor", label: "Klussen - Auto en Motor" },
        { value: "Klussen - Badkamermeubelen", label: "Klussen - Badkamermeubelen" },
        { value: "Klussen - Elektrisch gereedschap", label: "Klussen - Elektrisch gereedschap" },
        { value: "Klussen - Elektrisch gereedschap accessoires", label: "Klussen - Elektrisch gereedschap accessoires" },
        { value: "Klussen - Gereedschap, verf en behang", label: "Klussen - Gereedschap, verf en behang" },
        { value: "Klussen - Witte klusverf (emmers) ", label: "Klussen - Witte klusverf (emmers) " },
        { value: "Klussen - Groot Sanitair", label: "Klussen - Groot Sanitair" },
        { value: "Klussen - Opbergen en Verhuizen", label: "Klussen - Opbergen en Verhuizen" },
        { value: "Klussen - Sanitair en Veilig Wonen", label: "Klussen - Sanitair en Veilig Wonen" },
        { value: "Koken, Tafelen en Huishouden", label: "Koken, Tafelen en Huishouden" },
        { value: "Koken, Tafelen en Huishouden - Kookgerei", label: "Koken, Tafelen en Huishouden - Kookgerei" },
        { value: "Koken, Tafelen en Huishouden - Barbecues", label: "Koken, Tafelen en Huishouden - Barbecues" },
        { value: "Lezen en Leren - Boeken (zowel nieuw als tweedehands)***", label: "Lezen en Leren - Boeken (zowel nieuw als tweedehands)***" },
        { value: "Lezen en Leren - Ereader Accessoires", label: "Lezen en Leren - Ereader Accessoires" },
        { value: "Lezen en Leren - Ereaders", label: "Lezen en Leren - Ereaders" },
        { value: "Mode en Sport - Baby- en Kinderkleding", label: "Mode en Sport - Baby- en Kinderkleding" },
        { value: "Mode en Sport - Baby- en Kinderkleding", label: "Mode en Sport - Baby- en Kinderkleding" },
        { value: "Mode en Sport - Dameskleding", label: "Mode en Sport - Dameskleding" },
        { value: "Mode en Sport - Herenkleding", label: "Mode en Sport - Herenkleding" },
        { value: "Mode en Sport - Horloges en Zonnebrillen", label: "Mode en Sport - Horloges en Zonnebrillen" },
        { value: "Mode en Sport - Ondergoed en Zwemkleding", label: "Mode en Sport - Ondergoed en Zwemkleding" },
        { value: "Mode en Sport - Schoenen", label: "Mode en Sport - Schoenen" },
        { value: "Mode - Sieraden", label: "Mode - Sieraden" },
        { value: "Mode - Tassen, Reisbagage en Modeaccessoires", label: "Mode - Tassen, Reisbagage en Modeaccessoires" },
        { value: "School en Kantoor ", label: "School en Kantoor " },
        { value: "School en Kantoor - Elektrische kantoorartikelen", label: "School en Kantoor - Elektrische kantoorartikelen" },
        { value: "School en Kantoor - Puzzelboeken en tijdschriften", label: "School en Kantoor - Puzzelboeken en tijdschriften" },
        { value: "Speelgoed", label: "Speelgoed" },
        { value: "Speelgoed - Blokken en Vormen (m.u.v. DUPLO, LEGO, Playmobil)", label: "Speelgoed - Blokken en Vormen (m.u.v. DUPLO, LEGO, Playmobil)" },
        { value: "Speelgoed - Blokken en Vormen (DUPLO, LEGO, Playmobil)", label: "Speelgoed - Blokken en Vormen (DUPLO, LEGO, Playmobil)" },
        { value: "Speelgoed - Radiografisch voertuig", label: "Speelgoed - Radiografisch voertuig" },
        { value: "Speelgoed - Speelhuis, Trampoline en Zwembad", label: "Speelgoed - Speelhuis, Trampoline en Zwembad" },
        { value: "Sport Hardwaren - Duikcomputer, Duikhorloge en Duiklamp", label: "Sport Hardwaren - Duikcomputer, Duikhorloge en Duiklamp" },
        { value: "Sport Hardwaren - Duiken en Snorkelen overig", label: "Sport Hardwaren - Duiken en Snorkelen overig" },
        { value: "Sport Hardwaren - Elektrische fiets", label: "Sport Hardwaren - Elektrische fiets" },
        { value: "Sport Hardwaren - Fiets m.u.v. Elektrische fiets", label: "Sport Hardwaren - Fiets m.u.v. Elektrische fiets" },
        { value: "Sport Hardwaren - Fiets - Fietsendragers", label: "Sport Hardwaren - Fiets - Fietsendragers" },
        { value: "Sport Hardwaren - Fitness ", label: "Sport Hardwaren - Fitness " },
        { value: "Sport Hardwaren - Hartslagmeter", label: "Sport Hardwaren - Hartslagmeter" },
        { value: "Sport Hardwaren - Hengelsport en Outdooruitusting", label: "Sport Hardwaren - Hengelsport en Outdooruitusting" },
        { value: "Sport Hardwaren - Kamperen en Slapen", label: "Sport Hardwaren - Kamperen en Slapen" },
        { value: "Sport Hardwaren - Sportaccessoires", label: "Sport Hardwaren - Sportaccessoires" },
        { value: "Sport Hardwaren - Sportbescherming- en materialen", label: "Sport Hardwaren - Sportbescherming- en materialen" },
        { value: "Sport Hardwaren - Stappenteller en Stopwatch", label: "Sport Hardwaren - Stappenteller en Stopwatch" },
        { value: "Sport Hardwaren - Watersport en Bootuitrusting", label: "Sport Hardwaren - Watersport en Bootuitrusting" },
        { value: "Sport Hardwaren - Wintersport", label: "Sport Hardwaren - Wintersport" },
        { value: "Telefonie en Tablets (m.u.v. Apple)", label: "Telefonie en Tablets (m.u.v. Apple)" },
        { value: "Telefonie en Tablets - Apple", label: "Telefonie en Tablets - Apple" },
        { value: "Telefonie en Tablets - Accessoires", label: "Telefonie en Tablets - Accessoires" },
        { value: "Tuin en Kerst - Elektrisch tuingereedschap", label: "Tuin en Kerst - Elektrisch tuingereedschap" },
        { value: "Tuin en Kerst - Hogedrukreinigers en Robotmaaiers", label: "Tuin en Kerst - Hogedrukreinigers en Robotmaaiers" },
        { value: "Tuin en Kerst - Kerstbomen", label: "Tuin en Kerst - Kerstbomen" },
        { value: "Tuin en Kerst - Tuin en Kerst", label: "Tuin en Kerst - Tuin en Kerst" },
        { value: "Tuin en Kerst - Verse Boeketten", label: "Tuin en Kerst - Verse Boeketten" },
        { value: "TV en Audio - Accessoires", label: "TV en Audio - Accessoires" },
        { value: "TV en Audio - Activitytracker", label: "TV en Audio - Activitytracker" },
        { value: "TV en Audio - Home Entertainment", label: "TV en Audio - Home Entertainment" },
        { value: "TV en Audio - Muziekinstrumenten", label: "TV en Audio - Muziekinstrumenten" },
        { value: "TV en Audio - Navigatie", label: "TV en Audio - Navigatie" },
        { value: "TV en Audio - Personal Audio (m.u.v. In Ear oordoppen)", label: "TV en Audio - Personal Audio (m.u.v. In Ear oordoppen)" },
        { value: "TV en Audio - Personal Audio - In Ear oordoppen (m.u.v. A-merk)", label: "TV en Audio - Personal Audio - In Ear oordoppen (m.u.v. A-merk)" },
        { value: "TV en Audio - Personal Audio - In Ear oordoppen A-merk****", label: "TV en Audio - Personal Audio - In Ear oordoppen A-merk****" },
        { value: "TV en Audio - Smartwatch", label: "TV en Audio - Smartwatch" },
        { value: "TV en Audio - Sporthorloges", label: "TV en Audio - Sporthorloges" },
        { value: "TV en Audio - Televisie", label: "TV en Audio - Televisie" },
        { value: "Wonen - Lichtbron", label: "Wonen - Lichtbron" },
        { value: "Wonen - Lijsten en Posters", label: "Wonen - Lijsten en Posters" },
        { value: "Wonen - Meubelen", label: "Wonen - Meubelen" },
        { value: "Wonen - Sprei, Deken, Laken, Kussensloop en Molton", label: "Wonen - Sprei, Deken, Laken, Kussensloop en Molton" },
        { value: "Wonen - Textiel", label: "Wonen - Textiel" },
        { value: "Wonen - Verlichting (m.u.v. Slimme verlichting)", label: "Wonen - Verlichting (m.u.v. Slimme verlichting)" },
        { value: "Wonen - Slimme verlichting", label: "Wonen - Slimme verlichting" },
        { value: "Wonen - Woondecoratie en -accessoires", label: "Wonen - Woondecoratie en -accessoires" }
    ]



    return (
        <>

            <div >
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={"Maak een keuze"}
                    name="color"
                    options={colourOptions}
                    onChange={handleCategoryChange}
                    placeholder="Kies categorie"
                />
            </div >


            <div
                style={{
                    color: 'hsl(0, 0%, 40%)',
                    display: 'inline-block',
                    fontSize: 12,
                    fontStyle: 'italic',
                    marginTop: '1em',
                }}
            >

            </div>
        </>
    )
}

export default DropDownCategory
