import { React, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useRefresh } from '../hooks/useRefresh';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import Select from 'react-select';
import { useProductContext } from '../hooks/useProductContext';
import IncomingShipmentsCard from '../components/IncomingShipmentsCard';
import ExpectedShipmentsCard from '../components/ExpectedShipmentsCard';
import hostNameServer from '../configHostname';
import ProductNavBar from '../components/ProductNav';
import { useLogout } from '../hooks/useLogout';

function IncomingShipments() {
  const [shipments, setShipments] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [isClearable, setIsClearable] = useState(true);
  const [products1, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  setDefaultLocale('nl');
  registerLocale('nl', nl);
  const { user } = useAuthContext();
  useRefresh();

  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState();
  const [items, setItems] = useState([]);
  const [plussed, setPlussed] = useState(false);
  const [productOptions, setProductOptions] = useState();
  const { logout } = useLogout();

  // fetch product data
  useEffect(() => {
    async function fetchAllData() {
      const response = await fetch(`${hostNameServer}/api/products/getalloffers`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const json = await response.json();

      if (response.ok) {
        const sortJson = json.sort((a, b) => b.forecast - a.forecast);
        setProductOptions(sortJson);
      }
      if (response.statusText === 'Unauthorized') {
        logout();
      }
    }
    fetchAllData();
  }, []);

  const [productSelectOptions, setProductSelectOptions] = useState();

  //add products to options
  useEffect(() => {
    if (productOptions) {
      console.log('fire');
      const options = productOptions.map((option) => {
        return {
          value: option.offerId,
          label: `${option.productTitle} - EAN: ${option.ean}`,
        };
      });

      setProductSelectOptions(options);
    }
  }, [productOptions]);

  const handleAddItem = () => {
    if (selectedOption && inputValue) {
      const newItem = {
        inputValue,
        selectedOption,
      };

      setItems([...items, newItem]);
      setInputValue('');
      setSelectedOption(null);
      console.log(items);
      setKeySuffix((prevSuffix) => prevSuffix + 1);
      setPlussed(true);
    }
  };

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    console.log(selected.label); // Hier wordt de waarde van de geselecteerde optie afgedrukt
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    console.log(inputValue);
  };

  //get incoming shipments
  useEffect(() => {
    const fetchIncomingShipments = async () => {
      const response = await fetch(`${hostNameServer}/api/products/incomingshipment`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const json = await response.json();

      if (response.ok) {
        console.log(json);

        const expectedShipments = json;

        const shippingArray = [];

        expectedShipments.forEach((shipment) => {
          const products = JSON.parse(shipment.products);

          products.forEach((item) => {
            const existingItem = shippingArray.find((newItem) => newItem.offerId === item.offerId);

            if (existingItem) {
              existingItem.stock += parseInt(item.stock);
            } else {
              shippingArray.push({
                offerId: item.offerId,
                stock: parseInt(item.stock),
              });
            }
          });
        });

        const result = [];

        for (let i = 0; i < expectedShipments.length; i++) {
          const item = expectedShipments[i];

          // Parse de products-string naar een JavaScript-object
          const productData = JSON.parse(item.products);

          // Maak een nieuw object met de gewenste eigenschappen
          const newItem = {
            name: item.name,
            createddate: item.createddate,
            expecteddate: item.expecteddate,
            products: productData,
          };

          // Voeg het nieuwe object toe aan het resultaatarray
          result.push(newItem);
        }

        setShipments(result);
        setLoading(false);
      }
    };
    fetchIncomingShipments();
  }, []);

  //   useEffect(() => {
  //     const fetchProducts = async () => {
  //       const response = await fetch(
  //         `${hostNameServer}/api/products/getalloffers`,
  //         {
  //           headers: { Authorization: `Bearer ${user.token}` },
  //         }
  //       );

  //       const json = await response.json();
  //       console.log(json);

  //       if (response.ok) {
  //         const offerData = JSON.parse(json.offerdata.offerdata);
  //         const forecast = json.salesForecast.forecasts;
  //         const producttitles = json.producttitles.producttitles;
  //         const productimages = json.productimages.productimages;
  //         const stocklevels = json.ownedstock.ownedstock;

  //         for (const item of offerData) {
  //           const offerId = item.offerId;
  //           const matchingShippingItem = shippingArray.find(
  //             (shippingItem) => shippingItem.offerId === offerId
  //           );

  //           if (matchingShippingItem) {
  //             item.expectedStock = matchingShippingItem.stock;
  //           }
  //         }

  //         // Functie om de forecast waarde op basis van het offerId te vinden
  //         function findForecastValueByOfferId(offerId) {
  //           const matchingForecast = forecast.find(
  //             (item) => item.offerId === offerId
  //           );
  //           return matchingForecast ? matchingForecast.forecast : null;
  //         }

  //         // Functie om de producttitle op basis van het offerId te vinden
  //         function findProductTitleByOfferId(offerId) {
  //           const matchingProductTitle = producttitles.find(
  //             (item) => item.offerId === offerId
  //           );
  //           return matchingProductTitle
  //             ? matchingProductTitle.producttitle
  //             : null;
  //         }

  //         // Functie om de imageUrl op basis van het offerId te vinden
  //         function findImageUrlByOfferId(offerId) {
  //           const matchingImageUrl = productimages.find(
  //             (item) => item.offerId === offerId
  //           );
  //           return matchingImageUrl ? matchingImageUrl.imageUrl : null;
  //         }

  //         // Functie om het stockniveau op basis van het offerId te vinden
  //         function findStockLevelByOfferId(offerId) {
  //           const matchingStockLevel = stocklevels.find(
  //             (item) => item.offerId === offerId
  //           );
  //           return matchingStockLevel ? matchingStockLevel.stock : null;
  //         }

  //         // Itereer over de offerData-array en voeg de forecast, producttitle en imageUrl toe
  //         for (const item of offerData) {
  //           const offerId = item.offerId;

  //           // Zoek de forecast waarde op basis van het offerId
  //           const forecastValue = findForecastValueByOfferId(offerId);

  //           // Voeg de forecast waarde toe aan de offerData, als deze gevonden is
  //           if (forecastValue !== null) {
  //             item.forecast = forecastValue;
  //           }

  //           // Zoek de producttitle op basis van het offerId
  //           const productTitle = findProductTitleByOfferId(offerId);

  //           // Voeg de producttitle toe aan de offerData, als deze gevonden is
  //           if (productTitle !== null) {
  //             item.producttitle = productTitle;
  //           }

  //           // Zoek de imageUrl op basis van het offerId
  //           const imageUrl = findImageUrlByOfferId(offerId);

  //           // Voeg de imageUrl toe aan de offerData, als deze gevonden is
  //           if (imageUrl !== null) {
  //             item.imageUrl = imageUrl;
  //           }

  //           // Zoek het stockniveau op basis van het offerId
  //           const stockLevel = findStockLevelByOfferId(offerId);

  //           // Voeg het stockniveau toe aan de offerData, als deze gevonden is
  //           if (stockLevel !== null) {
  //             item.stocklevel = stockLevel;
  //           }
  //         }

  //         setLoading(false);

  //         const loadProductsInContext = offerData.sort(
  //           (a, b) => b.forecast - a.forecast
  //         );

  //         dispatch({ type: 'LOAD_PRODUCTS', payload: loadProductsInContext });
  //         setProducts(offerData.sort((a, b) => b.forecast - a.forecast));
  //         console.log(offerData);
  //       }
  //     };

  //     fetchProducts();
  //   }, []);

  const [keySuffix, setKeySuffix] = useState(0);

  const [shipmentName, setShipmentName] = useState('');
  function handleShipmentName(event) {
    setShipmentName(event.target.value);
  }

  async function updateShipment(shipmentName, expectedDate, products) {
    const response = await fetch(`${hostNameServer}/api/products/incomingshipment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        currentDate: Date.now,
        expectedData: expectedDate,
        name: shipmentName,
        products,
        products,
      }),
    });

    const json = await response.json();
    console.log(json);
  }

  function handleClick() {
    if (!items) {
      return;
    } else {
      updateShipment(shipmentName, startDate, items);

      document.getElementById('closeButton').click();

      setSelectedOption(null);
      setInputValue();
      setItems([]);
    }
  }

  console.log(shipments);

  return (
    <div>
      <ProductNavBar activeTab={'binnenkomendezendingen'} />
      {loading && (
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {shipments && shipments.length === 0 && <p className="text-center my-5">Geen openstaande zendingen gevonden</p>}

      {shipments && shipments.map((product, index) => <ExpectedShipmentsCard product={product} key={product.offerId} />)}

      <span
        onClick={handleAddItem}
        data-bs-toggle="modal"
        data-bs-target="#exampleModalCenter"
        className="ml-auto d-flex justify-content-center align-items-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#173057" class="bi bi-plus-circle-fill plusSign" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
        </svg>
      </span>

      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Binnenkomende zending toevoegen
              </h5>
            </div>
            <div class="modal-body">
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control form-control-lg shipmentName"
                  id="colFormLabelSm"
                  placeholder="Naam zending"
                  onChange={handleShipmentName}
                />
              </div>

              <label className="mt-3 pl-3">Zending verwacht op</label>
              <DatePicker locale="nl" selected={startDate} onChange={(date) => setStartDate(date)} />

              <div class="container-fluid">
                <div class="row">
                  <h5 className="productLabel">Producten</h5>
                </div>
              </div>

              {plussed && (
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-10 ">
                      <span>Productnaam</span>
                    </div>
                    <div class="col-md-2">Aantal</div>
                  </div>
                </div>
              )}
              {items && items.map((item, index) => <IncomingShipmentsCard key={index} product={item} />)}
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-10 nopaddingleft">
                    <Select
                      key={`my_unique_select_key__${keySuffix}`}
                      defaultValue={selectedOption}
                      onChange={handleSelectChange}
                      options={productSelectOptions}
                      isClearable={isClearable}
                    />
                  </div>
                  <div class="col-md-2 d-flex">
                    <input
                      onChange={handleInputChange}
                      value={inputValue}
                      type="Number"
                      style={{ 'margin-top': '0px' }}
                      class="form-control"
                      id="colFormLabelSm"
                      placeholder="250"
                    />
                    <span onClick={handleAddItem} className="ml-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#173057" class="bi bi-plus-circle-fill plusSign" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" id="closeButton" class="btn btn-secondary" data-bs-dismiss="modal">
                Sluiten
              </button>
              <button type="button" className="btn btn-primary saveShipment" onClick={handleClick}>
                Opslaan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomingShipments;
