import { useState } from "react";

export const useForgetPassword = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [succeed, setSucceed] = useState(null)

    

    const forgottenPassword = async (email) => {
        setError(null)
        setIsLoading(true)

        const response = await fetch('/api/user/wachtwoordreset', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        })
        const json = await response.json()
        console.log(json)

        if (!response.ok) {
            setError(json.error)
            console.log("Niet gelukt!")
        }

        if (response.ok) {
            setIsLoading(false)
            setSucceed(true)
        }



    }
    return { forgottenPassword, error, succeed, isLoading }
}