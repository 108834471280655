const hostname = window.location.hostname;
let hostNameServer;

if (hostname === 'localhost' || hostname === '127.0.0.1') {
  hostNameServer = "http://localhost:4000";
} else if (hostname === 'app.businessbaas.nl') {
  hostNameServer = "https://businessbaas-backend.onrender.com";
} else {
  // Onbekende host
  console.log('Toegang geweigerd');
}

export default hostNameServer;