import { useToast } from '../../context/ToastContext';
import hostNameServer from '../../configHostname';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';

export function useDeleteDatabaseDocument() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const showToast = useToast();

  const deleteDatabaseDocument = async (data) => {
    const collection = data.collection;
    const documentId = data.documentId;

    try {
      const response = await fetch(`${hostNameServer}/api/database/delete/${collection}/${documentId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      });

      const json = await response.json();

      if (response.ok) {
        showToast('Succesvol verwijderd');
        return json;
      }

      if (!response.ok) {
        showToast(json.error);
      }

      if (response.statusText === 'Unauthorized') {
        logout();
      }
    } catch (e) {
      showToast('Oops. Er ging iets mis!');
    }
  };

  return deleteDatabaseDocument;
}
