import React from 'react'

function eanTracker() {
  return (
    <div>
        <h1>
            Hello world
        </h1>
    </div>
  )
}

export default eanTracker