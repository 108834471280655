import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'react-copy-to-clipboard';

const CopyContentIcon = ({ size, className, role, copyValue }) => {
  return (
    <CopyToClipboard text={copyValue} className={`ClipBoardContentIcon ${className}`}>
      <ContentCopyIcon style={{ fontSize: size }} role={role} />
    </CopyToClipboard>
  );
};

export default CopyContentIcon;
