import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import hostNameServer from '../configHostname';
import { Link } from 'react-router-dom';

const ConceptReplenishment = ({ concept, removeFromArray }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;

  const handleDelete = (event) => {
    event.stopPropagation();
    async function deleteConcept() {
      const response = await fetch(
        `${hostNameServer}/api/products/deleteconcept`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ Id: concept._id }),
        }
      );

      const json = await response.json();

      if (response.ok) {
        console.log(json);
        removeFromArray(concept._id);
      }
    }
    deleteConcept();
  };

  return (
    <div>
      <div className="row conceptRow">
        <div className="col-lg-9 col-6">
          <Link className="picklistLink" to={`/picklist/${concept._id}`}>
            <div>{concept.title}</div>
          </Link>
        </div>
        <div className="col-lg-2 col-4">
          {concept.products.length} Artikelen
        </div>
        <div className="col-lg-1 col-1">
          <span onClick={handleDelete} className="">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConceptReplenishment;
