import { useState } from 'react'
import { useLocation } from "react-router-dom";
import hostNameServer from '../configHostname';

export const usePasswordReset = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const location = useLocation();
    const [succeed, setSucceed] = useState("")

    const userID = location.pathname.split("/")[2]
    const token = location.pathname.split("/")[3]

    const passReset = async (password1, password2) => {
        setIsLoading(true)
        setError(null)

        if (password1 !== password2) {
            throw Error(setError("Wachtwoorden zijn niet gelijk"))
        }

        const password = password1

        const response = await fetch(`${hostNameServer}/api/user/wachtwoord-reset/${userID}/${token}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password })
        })
        const json = await response.json()
        console.log(json)

        console.log(response)

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)

        }
        if (response.ok) {
            // update loading state
            setIsLoading(false)
            setSucceed(true)
            console.log("Bijgewerkt!")
        }



    }
    return { passReset, isLoading, error, succeed }
}