import { useEffect } from "react";

export const useRefresh = () => {
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    sessionStorage.setItem("lastUrl", window.location.pathname);
  };

  useEffect(() => {
    sessionStorage.removeItem("lastUrl");
  });
};
