import { React, useState, useRef, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import CurrencyFormat from "react-currency-format";
import boxImage from "../assets/box.png";
import planeImage from "../assets/plane.png";
import shippingImage from "../assets/ready-stock.png";
import { Link } from "react-router-dom";
import af0 from "../assets/0.png";
import af1 from "../assets/1.png";
import af2 from "../assets/2.png";
import af3 from "../assets/3.png";
import af4 from "../assets/4.png";
import af5 from "../assets/5.png";
import hostNameServer from "../configHostname";
import CopyContentIcon from "./UI/CopyContentIcon";

const ProductCard = ({ product }) => {
  const { user } = useAuthContext();

  // Initial state voor het stockNumber en bewerkingsmodus
  const [stockNumber, setStockNumber] = useState(0);
  const [expectedStockNumber, setExpectedStockNumber] = useState(0);
  const [initialStock, setInitialStock] = useState(product.ownedStock);
  const [initialExpectedStock, setInitialExpectedStock] = useState(
    product.expectedStock,
  );
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null); // Ref voor het inputveld

  const [sold, setSold] = useState();
  const [condition, setCondition] = useState();

  const [price, setPrice] = useState();
  const [totalPrice, setTotalPrice] = useState();

  useEffect(() => {
    if (product.ownedStock !== undefined) {
      setStockNumber(product.ownedStock); // Stel de oorspronkelijke waarde in als deze beschikbaar is
    }
  }, [initialStock]);
  useEffect(() => {
    if (product.expectedStock !== undefined) {
      setExpectedStockNumber(product.expectedStock); // Stel de oorspronkelijke waarde in als deze beschikbaar is
    }
  }, [initialStock]);

  // Functie om de bewerkingsmodus in te schakelen
  const enableEditMode = () => {
    setEditMode(true);
    setTimeout(() => {
      inputRef.current.focus(); // Focus op het inputveld na het inschakelen van de bewerkingsmodus
    }, 0);
  };

  // Functie om de bewerkingsmodus uit te schakelen en wijzigingen te bevestigen
  const disableEditMode = (e) => {
    if (e.key === "Enter") {
      setEditMode(false);
      setStockNumber(Number(e.target.value));
      // Voer hier de logica uit om de gewijzigde waarde op te slaan, bijvoorbeeld in de state of in de backend
      updatedStockForecast();

      const offerId = product.offerId;
      const value = e.target.value;
      updatedStockLevel(offerId, value);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(".stockChild")) {
        disableEditMode(e);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getInputWidth = () => {
    const digits = Math.max(2, String(stockNumber).length); // Minimale breedte van 2 cijfers
    return `${digits}ch`;
  };

  async function updatedStockLevel(offerId, value) {
    const response = await fetch(`${hostNameServer}/api/products/updatestock`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({ offerId: offerId, value: value }),
    });

    const json = await response.json();
    console.log(json);
  }

  function addDaysToDate(days) {
    const today = new Date(); // Huidige datum en tijd
    const futureDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000); // Voeg dagen toe aan huidige datum

    // Haal de relevante onderdelen van de datum op
    const year = futureDate.getFullYear().toString().slice(-2); // Jaar (2 cijfers)
    const month = (futureDate.getMonth() + 1).toString().padStart(2, "0"); // Maand (2 cijfers, van 01 tot 12)
    const day = futureDate.getDate().toString().padStart(2, "0"); // Dag (2 cijfers)

    // Controleer of de datum geldig is
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return ""; // Geen waarde tonen als de datum ongeldig is
    }

    const formattedDate = `${day}-${month}-${year}`; // Formateer de datum als 'dd-mm-yy'

    return formattedDate;
  }

  useEffect(() => {
    updatedStockForecast();

    // if (product.stocklevel !== undefined) {
    //     setStockNumber(product.stocklevel)
    // }
  });

  function updatedStockForecast() {
    const totalStockdays =
      (Number(product.LVBstock) +
        Number(stockNumber) +
        Number(expectedStockNumber)) /
      (Number(product.forecast) / 28);
    const futureDate = addDaysToDate(Math.round(totalStockdays));
    setSold(futureDate);

    function convertDaysToMonths(days) {
      if (!days) {
        return 0;
      }

      const months = Math.floor(days / 28);
      const remainingDays = days % 28;

      if (remainingDays < 14) {
        return Math.min(months, 5);
      } else {
        return Math.min(months + 1, 5);
      }
    }

    const monthsRemaining = convertDaysToMonths(totalStockdays);
    setCondition(monthsRemaining);

    setPrice(Number(product.price));
    const totalStock =
      Number(product.LVBstock) +
      Number(stockNumber) +
      Number(expectedStockNumber);
    setTotalPrice(price * totalStock);
  }

  const [totalStock, setTotalStock] = useState();

  useEffect(() => {
    if (product.expectedStock) {
      const totalStock =
        product.expectedStock + product.ownedStock + product.LVBstock;
      setTotalStock(totalStock);
    } else {
      const totalStock = product.ownedStock || 0 + product.LVBstock || 0;
      setTotalStock(totalStock);
    }
  }, [product]);

  const [purchaseStockValue, setPurchaseStockValue] = useState();

  useEffect(() => {
    if (product.purchasePrice) {
      setPurchaseStockValue(
        (product.expectedStock || 0 + product.ownedStock + product.LVBstock) *
          Number(product.purchasePrice),
      );
    } else {
      setPurchaseStockValue(0);
    }
  }, [stockNumber, totalStock]);

  const [totalProfit, setTotalProfit] = useState();

  useEffect(() => {
    if (product.profit) {
      setTotalProfit(
        (product.expectedStock ||
          0 + product.ownedStock ||
          0 + product.LVBstock ||
          0) * Number(product.profit),
      );
    } else {
      setTotalProfit("0");
    }
  }, [stockNumber, totalStock, product]);

  const [profitPercentage, setProfitPercentage] = useState();

  useEffect(() => {
    if (product.profit) {
      setProfitPercentage(
        (
          (Number(product.profit) / ((Number(product.price) / 121) * 100)) *
          100
        ).toFixed(1),
      );
    }
  }, [product]);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="productcard link-wrapper">
      <div className="row ">
        <div className="col-lg-1">
          <Link to={`/producten/${product.ean}`}>
            <img src={product.image} alt="" />
          </Link>
        </div>
        <div className="col-lg-3 TDmain">
          {/* <p className='trackerDetailsRemaining'><strong>{tracker.brand}</strong></p> */}
          <p
            className="ProductDetailsTitle mb-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={product.productTitle}
          >
            {product.shortTitle ? product.shortTitle : product.productTitle}
          </p>
          <div
            className="eanIconContainer d-flex"
            onMouseEnter={() => setIsHovered("ean")}
            onMouseLeave={() => setIsHovered(false)}
          >
            <p className="productDetailsRemaining m-0">EAN: {product.ean}</p>{" "}
            <svg
              width="13"
              height="13"
              s
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ms-1"
              role="button"
              onClick={() =>
                window.open(
                  `https://partner.bol.com/sdd/offeranalysis/stock/?product-id=${product.productId}`,
                  "_blank",
                )
              }
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 4C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44772 22 3V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68342 15.0976 9.29289 14.7071C8.90237 14.3166 8.90237 13.6834 9.29289 13.2929L18.5858 4H15ZM5 7C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16C16.2652 20 16.5196 19.8946 16.7071 19.7071C16.8946 19.5196 17 19.2652 17 19V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V19C19 19.7957 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7957 22 16 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V8C2 7.20435 2.31607 6.44129 2.87868 5.87868C3.44129 5.31607 4.20435 5 5 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H5Z"
                fill="currentColor"
              ></path>
            </svg>
            {isHovered === "ean" && (
              <CopyContentIcon
                size={13}
                className="ms-1"
                role="button"
                copyValue={product.ean}
              />
            )}
          </div>
          <div
            onMouseEnter={() => setIsHovered("bsku")}
            onMouseLeave={() => setIsHovered(false)}
            className="d-flex"
          >
            <p className="productDetailsRemaining mb-1">Bsku: {product.bsku}</p>
            {isHovered === "bsku" && (
              <CopyContentIcon
                size={13}
                className="ms-1"
                role="button"
                copyValue={product.bsku}
              />
            )}
          </div>
        </div>
        <div className="col-lg-1 col-6 productStockContainer">
          <div className="stockChild">
            <img src={planeImage} alt="Afbeelding" className="stockImage" />
            <p className="stockNumber">{expectedStockNumber}</p>
          </div>
          <div className="stockChild">
            <img src={shippingImage} alt="Afbeelding" className="stockImage" />
            {editMode ? (
              <input
                type="number"
                className="stockNumberInput"
                defaultValue={stockNumber}
                ref={inputRef} // Ref toewijzen aan het inputveld
                onKeyDown={disableEditMode}
                style={{ width: getInputWidth() }} // Dynamische breedte van het inputveld
                // autoFocus
              />
            ) : (
              <p className="stockNumber" onClick={enableEditMode}>
                {stockNumber}
              </p>
            )}
          </div>
          <div className="stockChild">
            <img src={boxImage} alt="Afbeelding" className="stockImage" />
            <p className="stockNumber">{product.LVBstock}</p>
          </div>
        </div>
        <div className="col-lg-1 col-6 align-items-center d-flex justify-content-center">
          <div className="forecast">
            <div>
              <p>{sold}</p>
              <p>
                {Math.round(product.forecast)}/<span>28d</span>
              </p>
              <p>
                <strong>{totalStock}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-4">
          {condition === 0 && (
            <img src={af0} alt="Afbeelding" className="conditionImage" />
          )}
          {condition === 1 && (
            <img src={af1} alt="Afbeelding" className="conditionImage" />
          )}
          {condition === 2 && (
            <img src={af2} alt="Afbeelding" className="conditionImage" />
          )}
          {condition === 3 && (
            <img src={af3} alt="Afbeelding" className="conditionImage" />
          )}
          {condition === 4 && (
            <img src={af4} alt="Afbeelding" className="conditionImage" />
          )}
          {condition === 5 && (
            <img src={af5} alt="Afbeelding" className="conditionImage" />
          )}
        </div>

        <div className="col-lg-1 col-4">
          <p className="PpriceP">
            <CurrencyFormat
              value={Number(product.price)}
              displayType={"text"}
              decimalScale={2}
              prefix={"€"}
              allowNegative={false}
              decimalSeparator={","}
              placeholder={"€0,00"}
              fixedDecimalScale={true}
            />
          </p>
        </div>
        <div className="col-lg-1 col-4">
          <p className="PpriceP profiteuro">
            <CurrencyFormat
              value={totalProfit}
              displayType={"text"}
              decimalScale={2}
              prefix={"€"}
              allowNegative={false}
              decimalSeparator={","}
              placeholder={"€0,00"}
              fixedDecimalScale={true}
            />
          </p>

          {profitPercentage && (
            <p className="PpriceP profitpercentage">{profitPercentage}%</p>
          )}
        </div>
        <div className="col-lg-1 col-4">
          <p className="PpriceP">
            <CurrencyFormat
              value={purchaseStockValue}
              displayType={"text"}
              decimalScale={2}
              prefix={"€"}
              allowNegative={false}
              decimalSeparator={","}
              placeholder={"€0,00"}
              fixedDecimalScale={true}
            />
          </p>
        </div>
        <div className="col-lg-1 col-4">
          <p className="PpriceP">
            <CurrencyFormat
              value={totalPrice}
              displayType={"text"}
              decimalScale={2}
              prefix={"€"}
              allowNegative={false}
              decimalSeparator={","}
              placeholder={"€0,00"}
              fixedDecimalScale={true}
            />
          </p>
        </div>
        <div className="col-lg-1 col-4">
          <p className="PpriceP">
            <CurrencyFormat
              value={totalProfit}
              displayType={"text"}
              decimalScale={2}
              prefix={"€"}
              allowNegative={false}
              decimalSeparator={","}
              placeholder={"€0,00"}
              fixedDecimalScale={true}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
