import { React, useState, useEffect } from 'react'
// import { useNavigate } from "react-router-dom";

// import { useAuthContext } from '../hooks/useAuthContext';
import FormInput from '../components/FormInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxesStacked, faEuroSign, faShop, faPlaneDeparture, faBoxesPacking, faPercent, faBabyCarriage, faTruckFast, faBox } from '@fortawesome/free-solid-svg-icons'

import DropDownCategory from '../components/DropDownCategory';
import CurrencyFormat from 'react-currency-format';
import { useRefresh } from '../hooks/useRefresh';



function ProfitCalculator() {
useRefresh();

    // useEffect(() => {
    //     // Sla de huidige URL op in de sessieopslag
    //     sessionStorage.setItem("lastUrl", window.location.pathname);
    // }, []);

    //add event listener to support refresh
    




    //states
    const [amount, setAmount] = useState();
    const [price, setPrice] = useState();
    const [calcPrice, setCalcPrice] = useState(0)
    const [transport, setTransport] = useState();
    const [remaining, setRemaining] = useState();
    const [bolCommission, setBolCommission] = useState({});
    const [salesPrice, setSalesPrice] = useState();
    const [calcSalesPrice, setCalcSalesPrice] = useState(0)
    const [vat, setVat] = useState(21);
    const [calcVAT, setCalcVAT] = useState(0)
    const [ownShipping, setOwnShipping] = useState()
    const [lvb, setLVB] = useState();
    const [lvbprice, setLVBPrice] = useState();
    const [ownShippingPrice, setOwnShippingPrice] = useState();
    const [totalShippingPrice, setTotalShippingPrice] = useState(0)
    const [profitPerPc, setProfitPerPc] = useState(0)
    const [fixedCommission, setFixedCommission] = useState(0)
    const [percentageCommission, setPercentageCommission] = useState(0)
    const [totalCommission, setTotalCommission] = useState(0)
    const [breakeven, setBreakeven] = useState(0)
    const [checkFilledIn, setCheckFilledIn] = useState(0)
    const [grossIncome, setGrossIncome] = useState(0)
    const [profit, setProfit] = useState(0)
    const [profitMargin, setProfitMargin] = useState(0)

    //delete
    // const [category, setCategory] = useState("")
    // const [percentage, setPercentage] = useState(0)

    // const [incomePerPc, SetIncomePerPc] = useState(0)



    //function to update bol commission
    function updateBolComission(commission) {
        setBolCommission(commission)
    }

    function convertCurrency(currency) {
        if (!currency) {
            return 0
        }
        if (currency) {
            const removeEuro = currency.replace('€', '');
            const removeComma = removeEuro.replace(",", ".")
            return removeComma
        }
    }



    useEffect(() => {

        const unformattedSalesPrice = +convertCurrency(salesPrice)

        if (bolCommission.type === 0) {
            if (unformattedSalesPrice <= 10) {
                setFixedCommission(bolCommission.underTen)
                setPercentageCommission((bolCommission.percentage / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 10 && unformattedSalesPrice <= 20) {
                setFixedCommission(bolCommission.underTwenty)
                setPercentageCommission((bolCommission.percentage / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 20) {
                setFixedCommission(bolCommission.aboveTwenty)
                setPercentageCommission((bolCommission.percentage / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            console.log(totalCommission)
        }
        if (bolCommission.type === 1) {
            if (unformattedSalesPrice <= 10) {
                setFixedCommission(bolCommission.underTen)
                setPercentageCommission((bolCommission.percentageUnderTwenty / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 10 && unformattedSalesPrice <= 20) {
                setFixedCommission(bolCommission.underTwenty)
                setPercentageCommission((bolCommission.percentageUnderTwenty / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 20) {
                setFixedCommission(bolCommission.aboveTwenty)
                setPercentageCommission((bolCommission.percentageAboveTwenty / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            console.log(totalCommission)
        }
        if (bolCommission.type === 2) {
            if (unformattedSalesPrice <= 10) {
                setFixedCommission(bolCommission.underTen)
                setPercentageCommission((bolCommission.percentageUnderHundred / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 10 && unformattedSalesPrice <= 20) {
                setFixedCommission(bolCommission.underTwenty)
                setPercentageCommission((bolCommission.percentageUnderHundred / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 20) {
                setFixedCommission(bolCommission.aboveTwenty)
                if (unformattedSalesPrice < 100) {
                    setPercentageCommission((bolCommission.percentageUnderHundred / 100) * unformattedSalesPrice)
                    setTotalCommission(fixedCommission + percentageCommission)
                }
                if (unformattedSalesPrice >= 100) {
                    setPercentageCommission((bolCommission.percentageAboveHundred / 100) * unformattedSalesPrice)
                    setTotalCommission(fixedCommission + percentageCommission)
                }
            }
            console.log(totalCommission)
        }
        if (bolCommission.type === 3) {
            if (unformattedSalesPrice <= 10) {
                setFixedCommission(bolCommission.underTen)
                setPercentageCommission((bolCommission.percentageUnderTwenty / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 10 && unformattedSalesPrice <= 20) {
                setFixedCommission(bolCommission.underTwenty)
                setPercentageCommission((bolCommission.percentageUnderTwenty / 100) * unformattedSalesPrice)
                setTotalCommission(fixedCommission + percentageCommission)
            }
            if (unformattedSalesPrice > 20) {
                setFixedCommission(bolCommission.aboveTwenty)
                if (unformattedSalesPrice > 20 && unformattedSalesPrice <= 50) {
                    setPercentageCommission((bolCommission.percentageTwentyFifty / 100) * unformattedSalesPrice)
                }
                if (unformattedSalesPrice > 50) {
                    setPercentageCommission((bolCommission.percentageAboveFifty / 100) * unformattedSalesPrice)
                }
                setTotalCommission(fixedCommission + percentageCommission)
            }
            console.log(totalCommission)
        }
        console.log("Dit is de commissie " + totalCommission)

        //check is amount, price and salesprice are filled in
        if (checkFilledIn === 1) {
            if (amount && salesPrice && price) {
                allCalcs()

            } else {
                setCalcSalesPrice(0)
                setCalcPrice(0)
                setTotalCommission(0)
                setCalcVAT(0)
                setTotalShippingPrice(0)
                setProfit(0)
                setProfitPerPc(0)
                setBreakeven(0)
                setGrossIncome(0)
            }

        }

        function allCalcs() {
            //calc salesprice
            setCalcSalesPrice(+convertCurrency(salesPrice))

            //calc price
            // if (isNaN(calcPrice) || !isFinite(calcPrice)) {
            //     setCalcPrice(0)
            //     console.log("IsNAN getriggerd")
            // }

            if (isFinite((+convertCurrency(price) + +convertCurrency(transport) + +convertCurrency(remaining)) / amount)) {
                setCalcPrice((+convertCurrency(price) + +convertCurrency(transport) + +convertCurrency(remaining)) / amount)
            }


            const calcVAT = convertCurrency(salesPrice) / (100 + +vat) * vat
            setCalcVAT(calcVAT)

            //calc shippingcosts
            if (isNaN(+convertCurrency(ownShippingPrice) + +lvbprice)) {
                setTotalShippingPrice(0)
            } else {
                setTotalShippingPrice(+convertCurrency(ownShippingPrice) + +lvbprice)
                console.log(totalShippingPrice)
            }

            const totalProfitPerPc = calcSalesPrice - calcPrice - calcVAT - totalCommission - totalShippingPrice
            setProfitPerPc(totalProfitPerPc)

            if (amount) {
                setProfit(profitPerPc * amount)
            }

            //breakeven berekenen
            if (profitPerPc < 0) {
                setBreakeven(0)
            } else {
                if (isNaN(Math.ceil((+convertCurrency(price) + +convertCurrency(transport) + +convertCurrency(remaining)) / (+calcPrice + +profitPerPc)))) {
                    setBreakeven(0)
                }
                if (isFinite(Math.ceil((+convertCurrency(price) + +convertCurrency(transport) + +convertCurrency(remaining)) / (+calcPrice + +profitPerPc)))) {
                    setBreakeven(Math.ceil((+convertCurrency(price) + +convertCurrency(transport) + +convertCurrency(remaining)) / (+calcPrice + +profitPerPc)))
                    console.log("breakeven: " + breakeven)
                } else {
                    setBreakeven(0)
                }

            }

            //grossincome
            setGrossIncome(+calcSalesPrice * amount)

            //profitmargin
            if (profitPerPc > 0) {
                setProfitMargin((profitPerPc / (+calcSalesPrice - +calcVAT)) * 100)
            } else {
                setProfitMargin(0)
            }



        }

    }, [salesPrice, amount, price, transport, remaining, vat, bolCommission, lvbprice, ownShippingPrice, fixedCommission, percentageCommission, totalCommission, checkFilledIn, calcPrice, totalShippingPrice, calcSalesPrice, calcVAT, profit, profitPerPc, breakeven, grossIncome, profitMargin])


    //handle functions
    function handleChange(event) {

        //Activate form checker 
        setCheckFilledIn(1)

        if (event.target.name === "amount") {
            setAmount(event.target.value)
            console.log(amount)
        }
        if (event.target.name === "price") {
            setPrice(event.target.value)
        }
        if (event.target.name === "transport") {
            setTransport(event.target.value)
            console.log(transport)
        }
        if (event.target.name === "remaining") {
            setRemaining(event.target.value)
            console.log(remaining)
        }
        if (event.target.name === "salesprice") {
            setSalesPrice(event.target.value)
            console.log(salesPrice)
        }
        if (event.target.name === "ownShippingPrice") {
            setOwnShippingPrice(event.target.value)
            console.log(ownShippingPrice)
        }
        if (event.target.name === "ownShippingPrice") {
            setOwnShippingPrice(event.target.value)
            console.log(ownShippingPrice)
        }
        if (event.target.name === "lvbsize") {
            console.log(event.target.value)

        }

        const input = event.target.value
        // console.log(input)
        if (input === "21") {
            setVat(21)
        }
        if (input === "9") {
            setVat(9)
        }
        if (input === "0") {
            setVat(0)
        }

        //shipment method
        if (input === "setLogistiekviaBol") {
            setOwnShipping()
            setLVB(event.target.value)
            setOwnShippingPrice(0)
        }
        if (input === "SetEigenVerzending") {
            setLVB()
            setOwnShipping(event.target.value)
            setLVBPrice(0)
        }
        if (input === "Maak een keuze") {
            setLVB()
            setOwnShipping()
            setOwnShippingPrice(0)
            setLVBPrice(0)
        }

    }

    function handleLVBChange(event) {
        const input = event.target.value
        setLVBPrice(input)
    }

    // function handleReset() {
    //     setCalcSalesPrice(0)
    //     setCalcPrice(0)
    //     setTotalCommission(0)
    //     setCalcVAT(0)
    //     setTotalShippingPrice(0)
    //     setProfit(0)
    //     setProfitPerPc(0)
    //     setBreakeven(0)
    //     setGrossIncome(0)
    //     setAmount("")
    //     setPrice("")
    //     setTransport("")
    //     setRemaining("")
    //     setSalesPrice("")
    //     setOwnShipping()
    //     setLVB()        
    // }



    return (
        <div className='profitStyling'>
            <div className='container'>
                <div className='row'>
                    <div class="col-lg-6 col-md-12">
                        <h2>Inkoop</h2>
                        <FormInput
                            text="Aantal stuks"
                            icon={<FontAwesomeIcon icon={faBoxesStacked} />}
                            placeholder={0}
                            name="amount"
                            value={amount}
                            onChange={handleChange}
                            className={checkFilledIn === 1 ? amount ? "form-control" : "form-control red-border" : "form-control "}
                            decimalScale={0}
                        />

                        <FormInput
                            text="Inkoopprijs excl. BTW"
                            icon={<FontAwesomeIcon icon={faEuroSign} />}
                            placeholder={"€0,00"}
                            name="price"
                            value={price}
                            onChange={handleChange}
                            className={checkFilledIn === 1 ? price ? "form-control" : "form-control red-border" : "form-control "}
                            decimalScale={2}
                            prefix={"€"}
                        // onValueChange={handleValueChange}

                        />
                        <FormInput
                            text="Transport kosten"
                            icon={<FontAwesomeIcon icon={faPlaneDeparture} />}
                            placeholder={"€0,00"}
                            name='transport'
                            value={transport}
                            onChange={handleChange}
                            className="form-control"
                            decimalScale={2}
                            prefix={'€'}
                        />
                        <FormInput
                            text="Overige kosten"
                            icon={<FontAwesomeIcon icon={faBoxesPacking} />}
                            placeholder={"€0,00"}
                            name='remaining'
                            value={remaining}
                            onChange={handleChange}
                            className="form-control"
                            decimalScale={2}
                            prefix={'€'}
                        />
                        <h2>Verkoop</h2>
                        <form >
                            <FormInput
                                text="Verkoopprijs incl. BTW"
                                icon={<FontAwesomeIcon icon={faShop} />}
                                placeholder={"€0,00"}
                                name='salesprice'
                                value={salesPrice}
                                onChange={handleChange}
                                className={checkFilledIn === 1 ? salesPrice ? "form-control" : "form-control red-border" : "form-control "}
                                decimalScale={2}
                                prefix={'€'}
                            />
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text label" for="inputGroupSelect01"><FontAwesomeIcon icon={faPercent} />BTW-tarief</label>
                                </div>
                                <select class="custom-select form-control" id="inputGroupSelect01" onChange={handleChange}>
                                    <option value='21' selected>21%</option>
                                    <option value="9">9%</option>
                                    <option value="0">0%</option>
                                </select>
                            </div>
                            <div class="input-group mb-3" >
                                <div class="input-group-prepend">
                                    <label class="input-group-text label labelMobile" for="inputGroupSelect01"><FontAwesomeIcon icon={faBabyCarriage} />Categorie</label>
                                </div>
                                <div class=" form-control nopadding" >
                                    <DropDownCategory
                                        updateCommission={updateBolComission}
                                        onChange={handleChange}
                                    />
                                </div>

                            </div>

                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text label labelMobile" for="inputGroupSelect01"><FontAwesomeIcon icon={faTruckFast} />Verzendwijze</label>
                                </div>
                                <select class="custom-select form-control" id="inputGroupSelect01" onChange={handleChange}>
                                    <option selected>Maak een keuze</option>
                                    <option value="setLogistiekviaBol">Logistiek via Bol</option>
                                    <option value="SetEigenVerzending">Eigen verzending</option>
                                </select>
                            </div>

                            {lvb && <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <label class="input-group-text label labelMobile" for="inputGroupSelect01"><FontAwesomeIcon icon={faBox} />Selecteer LVB maat</label>
                                </div>
                                <select class="custom-select form-control" id="inputGroupSelect01" onChange={handleLVBChange}>
                                    <option selected>Maak een keuze</option>
                                    <option value="2.37">XXXS 23,5cm x 16,5cm x 3cm (1kg)</option>
                                    <option value="3.02">XXS 37,5cm x 26cm x 3cm (2kg)</option>
                                    <option value="4.5">XS 37,5cm x 26cm x 5cm (5kg)</option>
                                    <option value="5.37">S 45cm x 30cm x 8cm (5kg)</option>
                                    <option value="5.52">M 55cm x 35cm x 20cm (8kg)</option>
                                    <option value="6.54">L 72cm x 50cm x 41cm (15kg) </option>
                                </select>
                            </div>}
                            {ownShipping && <FormInput
                                text="Verzendkosten"
                                icon={<FontAwesomeIcon icon={faBox} />}
                                placeholder={"€0,00"}
                                name='ownShippingPrice'
                                value={ownShippingPrice}
                                className="form-control labelMobile"
                                onChange={handleChange}
                                decimalScale={2}
                                prefix={'€'}
                            />}

                        </form>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div className='row'>
                            <div className='col-6'>
                                <h2>Winstberekening</h2>
                            </div>
                            {/* <div className='col-6'>
                                <h2 className='resetButton' >Reset</h2>
                            </div> */}
                        </div>

                        <div class="row">
                            <div className='col-6 winst'>
                                <div className={profitPerPc ? profitPerPc > 0 ? "profitdivGreen" : "profitdivRed" : "profitdiv"}>
                                    <span>Winst per stuk</span> <br />
                                    <CurrencyFormat
                                        value={profitPerPc}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={"€"}
                                        allowNegative={true}
                                        decimalSeparator={","}
                                        placeholder={"€0,00"}
                                        fixedDecimalScale={true}
                                        isNumericString={true}
                                        className='profitNumber'
                                    />
                                </div>



                            </div>
                            <div className='col-6 winst '>
                                <div className={profitPerPc ? profitPerPc > 0 ? "profitdivGreen" : "profitdivRed" : "profitdiv"}>
                                    <span>Totale winst</span> <br />
                                    <CurrencyFormat
                                        value={profit}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={"€"}
                                        allowNegative={true}
                                        decimalSeparator={","}
                                        placeholder={"€0,00"}
                                        fixedDecimalScale={true}
                                        isNumericString={true}
                                        className='profitNumber'
                                    />
                                </div>
                            </div>
                            <div className='col-6 winst '>
                                <div className='profitdiv'>
                                    <span>Breakeven</span> <br />
                                    <CurrencyFormat
                                        value={breakeven}
                                        displayType={'text'}
                                        decimalScale={0}
                                        prefix={""}
                                        allowNegative={true}
                                        decimalSeparator={""}
                                        placeholder={"0"}
                                        fixedDecimalScale={true}
                                        isNumericString={true}
                                        suffix=' stuks'
                                        className='profitNumber'
                                    />
                                </div>
                            </div>
                            <div className='col-6 winst '>
                                <div className='profitdiv'>
                                    <span>Totale omzet</span> <br />
                                    <CurrencyFormat
                                        value={grossIncome}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={"€"}
                                        allowNegative={true}
                                        decimalSeparator={","}
                                        placeholder={"0"}
                                        fixedDecimalScale={true}
                                        isNumericString={true}
                                        className='profitNumber'
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row profitSpecification">
                            <div className='col-md-6 col-9'>
                                <p>Verkoopprijs <span className='smallBTW'>incl. BTW</span></p>
                            </div>
                            <div className='col-md-6 col-3'>
                                <p><CurrencyFormat
                                    value={calcSalesPrice}
                                    displayType={'text'}
                                    decimalScale={2}
                                    prefix={"€"}
                                    allowNegative={false}
                                    decimalSeparator={","}
                                    placeholder={"€0,00"}
                                    fixedDecimalScale={true}
                                /></p>
                            </div>
                            <div className='col-md-6 col-9'>
                                <p>Inkoopkosten <span className='smallBTW'>excl. BTW</span></p>
                            </div>
                            <div className='col-md-6 col-3'>
                                <p><CurrencyFormat
                                    value={calcPrice}
                                    displayType={'text'}
                                    decimalScale={2}
                                    prefix={"€"}
                                    allowNegative={false}
                                    decimalSeparator={","}
                                    placeholder={"€0,00"}
                                    fixedDecimalScale={true}
                                /></p>
                            </div>
                            <div className='col-md-6 col-9'>
                                <p>BTW</p>
                            </div>
                            <div className='col-md-6 col-3'>
                                <p><CurrencyFormat
                                    value={calcVAT}
                                    displayType={'text'}
                                    decimalScale={2}
                                    prefix={"€"}
                                    allowNegative={false}
                                    decimalSeparator={","}
                                    placeholder={"€0,00"}
                                    fixedDecimalScale={true}
                                /></p>
                            </div>
                            <div className='col-md-6 col-9'>
                                <p>Commissie <span className='smallBTW'>excl. BTW</span></p>
                            </div>
                            <div className='col-md-6 col-3'>
                                <p><CurrencyFormat
                                    value={totalCommission}
                                    displayType={'text'}
                                    decimalScale={2}
                                    prefix={"€"}
                                    allowNegative={false}
                                    decimalSeparator={","}
                                    placeholder={"€0,00"}
                                    fixedDecimalScale={true}
                                /></p>
                            </div>
                            <div className='col-md-6 col-9'>
                                <p>Verzendkosten <span className='smallBTW'>excl. BTW</span></p>
                            </div>
                            <div className='col-md-6 col-3'>
                                <p><CurrencyFormat
                                    value={totalShippingPrice}
                                    displayType={'text'}
                                    decimalScale={2}
                                    prefix={"€"}
                                    allowNegative={false}
                                    decimalSeparator={","}
                                    placeholder={"€0,00"}
                                    fixedDecimalScale={true}
                                /></p>
                            </div>
                            <div className='col-md-6 col-9'>
                                <p style={{ "margin-bottom": 0 }}><strong>Winstmarge</strong></p>
                            </div>
                            <div className='col-md-6 col-3'>
                                <p style={{ "margin-bottom": 0 }}><strong><CurrencyFormat
                                    value={profitMargin}
                                    displayType={'text'}
                                    decimalScale={1}
                                    suffix='%'
                                    allowNegative={false}
                                    decimalSeparator={","}
                                    placeholder={"0%"}
                                    fixedDecimalScale={true}
                                /></strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ProfitCalculator