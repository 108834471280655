import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CopyContentIcon from "./UI/CopyContentIcon";

const StockManagementCard = ({ product, handleStockChange, newArray }) => {
  const [minus, setMinus] = useState("-");
  const [inputValue, setInputValue] = useState();
  const [calc, setCalc] = useState(product.ownedStock);

  function handleClick() {
    if (minus === "-") {
      setMinus("+");
    } else {
      setMinus("-");
    }
  }
  function handleChange(event) {
    const newValue = +event.target.value;

    setInputValue(newValue);

    if (minus === "-") {
      setCalc(product.ownedStock - newValue);
    } else {
      setCalc(product.ownedStock + newValue);
    }
  }

  useEffect(() => {
    if (calc !== product.ownedStock) {
      handleStockChange(product.offerId, calc);
    } else {
      handleStockChange(product.offerId, undefined);
    }
  }, [calc, inputValue, product]);

  //calc value
  useEffect(() => {
    if (minus === "-") {
      setCalc(product.ownedStock - (inputValue || 0));
    } else {
      setCalc(product.ownedStock + (inputValue || 0));
    }
  }, [product, inputValue, minus]);

  useEffect(() => {
    setInputValue(product.quantityAnnounced);
  }, [product.quantityWithRegularState]);

  const [temp, setTemp] = useState();

  //temporaliy
  useEffect(() => {
    if (newArray) {
      const findValue = newArray.find(
        (item) => item.offerId === product.offerId,
      );
      if (findValue !== undefined) {
        product.ownedStock !== calc ? setTemp(findValue.value) : setTemp("N/C");
      } else {
        setTemp("NiA");
      }
    }
  }, [newArray]);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <div className="productcard link-wrapper">
        <div className="row ">
          <div className="col-lg-2">
            <Link to={`/producten/${product.ean}`}>
              <img src={product.image} alt="" />
            </Link>
          </div>
          <div className="col-lg-3 TDmain">
            {/* <p className='trackerDetailsRemaining'><strong>{tracker.brand}</strong></p> */}
            <p
              className="ProductDetailsTitle mb-2"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={product.productTitle}
            >
              {product.shortTitle ? product.shortTitle : product.productTitle}
            </p>
            <div
              className="eanIconContainer d-flex"
              onMouseEnter={() => setIsHovered("ean")}
              onMouseLeave={() => setIsHovered(false)}
            >
              <p className="productDetailsRemaining m-0">EAN: {product.ean}</p>{" "}
              <svg
                width="13"
                height="13"
                s
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ms-1"
                role="button"
                onClick={() =>
                  window.open(
                    `https://partner.bol.com/sdd/offeranalysis/stock/?product-id=${product.productId}`,
                    "_blank",
                  )
                }
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 4C14.4477 4 14 3.55228 14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44772 22 3V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L10.7071 14.7071C10.3166 15.0976 9.68342 15.0976 9.29289 14.7071C8.90237 14.3166 8.90237 13.6834 9.29289 13.2929L18.5858 4H15ZM5 7C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16C16.2652 20 16.5196 19.8946 16.7071 19.7071C16.8946 19.5196 17 19.2652 17 19V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V19C19 19.7957 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7957 22 16 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V8C2 7.20435 2.31607 6.44129 2.87868 5.87868C3.44129 5.31607 4.20435 5 5 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H5Z"
                  fill="currentColor"
                ></path>
              </svg>
              {isHovered === "ean" && (
                <CopyContentIcon
                  size={13}
                  className="ms-1"
                  role="button"
                  copyValue={product.ean}
                />
              )}
            </div>
            <div
              onMouseEnter={() => setIsHovered("bsku")}
              onMouseLeave={() => setIsHovered(false)}
              className="d-flex"
            >
              <p className="productDetailsRemaining mb-1">
                Bsku: {product.bsku}
              </p>
              {isHovered === "bsku" && (
                <CopyContentIcon
                  size={13}
                  className="ms-1"
                  role="button"
                  copyValue={product.bsku}
                />
              )}
            </div>
          </div>
          <div className="col-lg-1 verticalAlign text-center">
            {product.quantityAnnounced}
          </div>
          <div className="col-lg-1 verticalAlign text-center">
            {product.quantityReceived}
          </div>
          <div className="col-lg-1 verticalAlign text-center">
            {product.quantityInProgress}
          </div>
          <div
            className={
              product.quantityAnnounced !== product.quantityWithRegularState
                ? "col-lg-1 verticalAlign text-center text-danger fw-bold"
                : "col-lg-1 verticalAlign text-center"
            }
          >
            {product.quantityWithRegularState}
          </div>

          <div className="col-lg-3 verticalAlign">
            <div className="d-flex align-items-center ps-5">
              <span className="me-3" style={{ minWidth: "35px" }}>
                {product.ownedStock}
              </span>
              <div
                className={
                  minus === "-"
                    ? "me-3 minusBackground text-center verticalAlign"
                    : "me-3 minusBackground minusBackgroundRed text-center verticalAlign"
                }
                onClick={handleClick}
              >
                {minus}
              </div>
              <span className="me-3">
                <input
                  style={{ width: "60px" }}
                  className={
                    product.quantityAnnounced !==
                    product.quantityWithRegularState
                      ? "text-center border-warning border-2"
                      : "text-center"
                  }
                  type="number"
                  onChange={handleChange}
                  value={inputValue}
                />
              </span>
              <span className="me-3">=</span>
              <span className={calc < 0 ? "fw-bold text-danger" : ""}>
                {calc}{" "}
              </span>
              <span> / {temp}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockManagementCard;
