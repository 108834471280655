import { React, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { useTrackerContext } from '../hooks/useTrackerContext'
import { useAuthContext } from '../hooks/useAuthContext';
import { useRefresh } from '../hooks/useRefresh';


//components
import TrackerDetails from '../components/TrackerDetails';

function PriceTracker() {
    const { trackers, dispatch } = useTrackerContext();
    const { user } = useAuthContext();
    useRefresh();







    useEffect(() => {
        const fetchTrackers = async () => {
            const response = await fetch("/api/tracker/alltrackers", {
                headers: { 'Authorization': `Bearer ${user.token}` }
            })
            const json = await response.json()
            console.log(json)

            if (response.ok) {
                dispatch({ type: "LOAD_TRACKERS", payload: json })
            }
        }
        if (user) {
            fetchTrackers()
        }
    }, [dispatch, user])

    //Handle toevoegen
    const [ean, setEan] = useState("")
    // const [eanCom, setEanCom] = useState("")

    const navigate = useNavigate();
    const [error, setError] = useState(null)


    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/tracker/newtracker', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ ean })
        })
        const json = await response.json()
        console.log(json)

        if (!response.ok) {
            console.log("Tracking is niet gelukt")
            setError(json.error)
        }
        if (response.ok) {
            console.log("product wordt nu getrackt")
            navigate("/pricetracker")
        }

    }

    return (
        <div className='backgroundTracker'>
            <div className='trackerAddButton'>
                <Link to="/pricetracker/create" className="btn btn-primary ">+ Toevoegen</Link>
            </div>
            <div className='trackerAddButton'>
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                    + Toevoegen
                </button>
            </div>



            {trackers && trackers.map((tracker) => (
                <TrackerDetails tracker={tracker} />
            ))}

            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Product Toevoegen</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="home">
                                <form  >
                                    <label>Welk product wil je tracken?</label>
                                    <div className='addProductInput'>
                                        <input
                                            type="text"
                                            onChange={(e) => setEan(e.target.value)}
                                            value={ean}
                                            placeholder='8719327452585'
                                        />
                                    </div>
                                    {error && <div className='error'>{error}</div>}

                                </form>
                            </div>
                            <div class="modal-footer">

                                <button type="button" class="btn btn-primary orangeButton" onClick={handleSubmit} data-bs-dismiss="modal">Product Tracken</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceTracker