import React from 'react';
import { Link } from 'react-router-dom';

const ProductNavBar = ({ activeTab }) => {
  return (
    <div className="hideOnMobile mb-3">
      <ul className="nav nav-underline">
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === 'binnenkomendezendingen' ? 'active' : ''}`} to="/binnenkomendezendingen">
            Binnenkomende zendingen
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === 'tracecodes' ? 'active' : ''}`} to="/tracecodes">
            Tracecodes
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === 'productenv2' ? 'active' : ''}`} to="/producten">
            Producten
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === 'lvb' ? 'active' : ''}`} to="/lvb">
            LVB zending
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === 'paklijst' ? 'active' : ''}`} to="/picklist">
            Paklijst
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === 'stockmanagement' ? 'active' : ''}`} to="/stockmanagement">
            Stock Management
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ProductNavBar;
