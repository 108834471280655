import { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export function useToast() {
  return useContext(ToastContext);
}

export function ToastProvider({ children }) {
  const [toastContent, setToastContent] = useState('Geen melding');

  const showToastFunction = (title) => {
    setToastContent(title);
    const liveToast = document.getElementById('liveToast');
    const toast = new window.bootstrap.Toast(liveToast);
    toast.show();
  };

  return (
    <ToastContext.Provider value={showToastFunction}>
      {children}

      <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
        <div id="liveToast" class="toast align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="d-flex">
            <div class="toast-body">{toastContent}</div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </ToastContext.Provider>
  );
}
