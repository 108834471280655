import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

// pages & components
import Home from "./pages/Home";
import Navbar from "./components/navbar";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import PriceTracker from "./pages/PriceTracker";
import AddTracker from "./pages/AddTracker";
import ProfitCalculator from "./pages/ProfitCalculator";
import EanTracker from "./pages/eanTracker";
import Products from "./pages/Products";
import IncomingShipments from "./pages/IncomingShipments";
import LVBShipment from "./pages/LVBshipment";
import ProductPage from "./pages/ProductPage";
import Picklist from "./pages/Picklist";
import ProductsV2 from "./pages/ProductsV2";
import PicklistPage from "./pages/PicklistPage";
import StockManagement from "./pages/StockManagement";
import TraceCodes from "./pages/TraceCodes";
// import Toast from './components/UI/Toast';
import { ToastProvider } from "./context/ToastContext";
import { ModalProvider } from "./context/ModalContext";

function App() {
  const { user } = useAuthContext();

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <ToastProvider>
            <ModalProvider>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={user ? <Home /> : <Navigate to="/login" />}
                />
                <Route
                  path="/pricetracker"
                  element={user ? <PriceTracker /> : <Navigate to="/login" />}
                />
                <Route
                  path="/winstcalculator"
                  element={
                    user ? <ProfitCalculator /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/producten"
                  element={user ? <ProductsV2 /> : <Navigate to="/login" />}
                />
                <Route
                  path="/producten/:ean"
                  element={user ? <ProductPage /> : <Navigate to="/login" />}
                />
                <Route
                  path="/producten/:ean/:picklist"
                  element={user ? <ProductPage /> : <Navigate to="/login" />}
                />
                <Route
                  path="/picklist/:id"
                  element={user ? <PicklistPage /> : <Navigate to="/login" />}
                />
                <Route
                  path="/binnenkomendezendingen"
                  element={
                    user ? <IncomingShipments /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/lvb"
                  element={user ? <LVBShipment /> : <Navigate to="/login" />}
                />
                <Route
                  path="/tracecodes"
                  element={user ? <TraceCodes /> : <Navigate to="/login" />}
                />
                <Route
                  path="/pricetracker/create"
                  element={user ? <AddTracker /> : <Navigate to="/login" />}
                />
                <Route
                  path="/pricetracker/:ean"
                  element={user ? <EanTracker /> : <Navigate to="/login" />}
                />
                <Route
                  path="/login"
                  element={!user ? <Login /> : <Navigate to="/" />}
                />
                <Route
                  path="/signup"
                  element={!user ? <Signup /> : <Navigate to="/" />}
                />
                <Route
                  path="/wachtwoordvergeten"
                  element={<ForgotPassword />}
                />
                <Route
                  path="/wachtwoord-reset/:userID/:token"
                  element={<PasswordReset />}
                />
                <Route path="/picklist" element={<Picklist />} />
                <Route path="/stockmanagement" element={<StockManagement />} />
              </Routes>
            </ModalProvider>
          </ToastProvider>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
