import { React, useEffect, useState } from "react";
import ProductNavBar from "../components/ProductNav";
import { useLogout } from "../hooks/useLogout";
import hostNameServer from "../configHostname";
import { useAuthContext } from "../hooks/useAuthContext";
import StockManagementCard from "../components/StockManagementCard";
import EmailIcon from "@mui/icons-material/Email";
import { useToast } from "../context/ToastContext";
import { useModal } from "../context/ModalContext";
import SimpleBadge from "../components/UI/MailIconBadge";

function StockManagement() {
  const showToast = useToast();
  const showModal = useModal();
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const [loading, setLoading] = useState();
  const [products, setProducts] = useState();

  // fetch product data
  useEffect(() => {
    async function fetchAllData(token) {
      const response = await fetch(
        `${hostNameServer}/api/products/getalloffers`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const json = await response.json();

      if (response.ok) {
        const sortJson = json.sort((a, b) => b.forecast - a.forecast);


        // const removeUndefinedStock = sortJson.filter(
        //   (item) => item.ownedStock >= 0,
        // );

        setProducts(sortJson);
        setLoading(false);
      }
      if (response.statusText === "Unauthorized") {
        logout();
      }
    }
    if (user) {
      const token = user.token;
      fetchAllData(token);
    }
  }, [user]);

  const [replenishmentReferences, setReplenishmentReferences] = useState();
  const [disableSelect, setDisableSelect] = useState(true);

  //get shipment references
  useEffect(() => {
    async function fetchAllReplenishments(token) {
      const response = await fetch(
        `${hostNameServer}/api/products/getallreplenishmentreferences`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const json = await response.json();

      if (response.ok) {
        getAllReplenishmentReferences(token, json);
        setDisableSelect(false);
      }
      if (response.statusText === "Unauthorized") {
        logout();
      }
    }
    if (user) {
      const token = user.token;
      fetchAllReplenishments(token);
    }
  }, [user]);

  //remove save button when negative number
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [forceInvalidNumber, setForceInvalidNumber] = useState(false);
  const [newArray, setNewArray] = useState([]);

  //handle all new stocks of products
  async function handleStockChange(offerId, quantity) {
    const existingItemIndex = newArray.findIndex(
      (item) => item.offerId === offerId,
    );
    // console.log(quantity);

    if (existingItemIndex === -1) {
      if (quantity || quantity === 0) {
        const newObject = {
          offerId,
          value: quantity,
        };

        setNewArray((prevArray) => {
          const updatedArray = [...prevArray, newObject];
          return updatedArray;
        });
      } else {
      }
    } else {
      // console.log(quantity);
      if (!isNaN(quantity)) {
        setNewArray((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[existingItemIndex] = {
            offerId,
            value: quantity,
          };
          return updatedArray;
        });
      } else {
        setNewArray((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray.splice(existingItemIndex, 1);
          return updatedArray;
        });
      }
    }
  }

  useEffect(() => {
    const findInvalidNumber = newArray.find((item) => item.value < 0);

    if (findInvalidNumber) {
      setInvalidNumber(true);
    } else {
      if (newArray.length === 0) {
        setInvalidNumber(true);
      } else {
        setInvalidNumber(false);
      }
    }
  }, [newArray]);

  const [specificProducts, setSpecificProducts] = useState();
  const [replenishmentName, setReplenishmentName] = useState();
  const [extendedShipmentInfo, setExtendedShipmentInfo] = useState();

  const handleSelectChange = (event) => {
    setInvalidNumber(false);
    setNewArray([]);
    const selectedOption = event.target.value;
    setReplenishmentName(selectedOption);
    console.log(selectedOption);

    const findIfValid = processedReferences.find(
      (item) => item.replenishmentReference === selectedOption,
    );

    findIfValid ? setForceInvalidNumber(true) : setForceInvalidNumber(false);

    async function fetchSpecificReplenishments(token, id) {
      const response = await fetch(
        `${hostNameServer}/api/products/getspecificreplenishment/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const json = await response.json();

      if (response.ok) {
        setExtendedShipmentInfo(json);

        const repProducts = json.lines;

        let newArr = [];

        for (const product of repProducts) {
          const findProduct = products.find((item) => item.ean === product.ean);

          const newObject = {
            ...findProduct,
            quantityAnnounced: product.quantityAnnounced,
            quantityInProgress: product.quantityInProgress,
            quantityReceived: product.quantityReceived,
            quantityWithRegularState: product.quantityWithRegularState,
          };
          newArr.push(newObject);
        }

        setSpecificProducts(newArr);
      }

      if (response.statusText === "Unauthorized") {
        logout();
      }
    }
    if (user) {
      const token = user.token;
      fetchSpecificReplenishments(token, selectedOption);
    }
  };

  //send bulk update to db
  async function sendBulkUpdateToDb() {
    try {
      const response = await fetch(
        `${hostNameServer}/api/products/bulkupdatestock`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({
            replenishmentName,
            products: newArray,
          }),
        },
      );

      const json = await response.json();

      if (response.ok) {
        showToast("Yes! De voorraad is bijgewerkt!");
      }
      if (response.statusText === "Unauthorized") {
        showToast("Log opnieuw in");
        logout();
      }
    } catch (e) {
      console.log(e);
      showToast("Oeps, er ging iets fout.");
    }
  }

  async function handleBulkUpdate() {
    showModal(
      "Weet je het zeker?",
      "Deze actie kan niet ongedaan gemaakt worden.",
      () => {
        sendBulkUpdateToDb();
      },
    );
  }

  // useEffect(() => {
  //   if (user) {
  //     const token = user.token;
  //     getAllReplenishmentReferences(token);
  //   }
  // }, [replenishmentReferences]);

  const [processedReferences, setProcessedReferences] = useState();

  async function getAllReplenishmentReferences(token, referenceArray) {
    const response = await fetch(
      `${hostNameServer}/api/products/getallprocessedreplenishmentreferences`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      },
    );

    const json = await response.json();

    if (response.ok) {
      let newArr = [];

      for (const obj of referenceArray) {
        const findItem = json.find(
          (item) => item.replenishmentReference === obj.replenishmentId,
        );

        if (findItem) {
          obj.reference = "✅ " + obj.reference;
          newArr.push(obj);
        } else {
          newArr.push(obj);
        }
      }

      newArr.sort((a, b) => {
        const refA = parseInt(a.replenishmentId, 10);
        const refB = parseInt(b.replenishmentId, 10);
        return refB - refA;
      });

      setProcessedReferences(json);
      setReplenishmentReferences(newArr);
    }
    if (response.statusText === "Unauthorized") {
      logout();
    }
  }

  function handleSendEmail() {
    const mistakenProducts = specificProducts
      .filter(
        (product) =>
          product.quantityAnnounced !== product.quantityWithRegularState,
      )
      .sort((a, b) => {
        const verschilA = a.quantityAnnounced - a.quantityWithRegularState;
        const verschilB = b.quantityAnnounced - b.quantityWithRegularState;

        return verschilB - verschilA;
      });
    console.error(mistakenProducts);

    const nameOfReplenishment = replenishmentReferences.find(
      (item) => item.replenishmentId === replenishmentName,
    ).reference;

    const introduction = `Beste,\n\n Korte geleden heb ik ${nameOfReplenishment} aangemeld bij LVB.\n Deze ${extendedShipmentInfo.loadCarriers.length} zijn inmiddels geleverd in het Bol.com magazijn. \n Met de grootste zorg tellen we altijd onze producten voordat we ze versturen naar het Bol.com Magazijn. \n Maar het lijkt erop dat de onderstaande producten niet correct verwerkt zijn.\n\n`;
    const incorrectProducts = mistakenProducts
      .map((item) => {
        const verschil = item.quantityWithRegularState - item.quantityAnnounced;
        const aantalStuks = Math.abs(verschil);
        const teVeelOfTeWeinig = verschil > 0 ? "te veel" : "te weinig";

        return `EAN: ${item.ean} => Aantal stuks aangeleverd: ${item.quantityAnnounced} => Aantal stuks op voorraad gelegd: ${item.quantityWithRegularState} => In totaal ${aantalStuks} stuks ${teVeelOfTeWeinig} op voorraad gelegd`;
      })
      .join("\n");
    const closure = `\n\n Het zou erg fijn zijn als hier naar gekeken kan worden, want we weten praktisch zeker dat de aantallen kloppen die we aangeleverd hebben. \n\n Hartelijk dank alvast, \n\n Vriendelijke groet, \n Rik Maaskant`;

    const email = "zakelijkverkopen@bol.com";
    const subject = `Vraag over ${nameOfReplenishment}`;
    const body = introduction + incorrectProducts + closure;

    const encodedEmail = encodeURIComponent(email);
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    const mailtoUrl = `mailto:${encodedEmail}?subject=${encodedSubject}`;

    window.location.href = mailtoUrl;
    navigator.clipboard.writeText(body).then(() => {
      showToast("Emailtekst is gekopieerd naar het klembord!");
    });
  }

  // useEffect(() => {
  //   console.warn(specificProducts)
  //
  // }, [specificProducts])

  return (
    <div>
      <div className="row hideOnMobile">
        <div className="col-8">
          <ProductNavBar activeTab={"stockmanagement"} />
        </div>
        <div className="col-lg-3 col-8 d-flex flex-row justify-content-end">
          {replenishmentName !== undefined &&
            replenishmentName !== "default" && (
              <div>
                <button className="btn me-2" onClick={handleSendEmail}>
                  <SimpleBadge
                    number={
                      (specificProducts &&
                        specificProducts.filter(
                          (product) =>
                            product.quantityAnnounced !==
                            product.quantityWithRegularState,
                        ).length) ||
                      ""
                    }
                  />
                </button>
              </div>
            )}
          <div className="visible-xs-w-75">
            <select
              id="selectReplenishment"
              class="form-select"
              aria-label="Default select example"
              disabled={disableSelect}
              onChange={handleSelectChange}
            >
              <option value="default" selected>
                Kies een zending
              </option>
              {replenishmentReferences &&
                replenishmentReferences.map((reference, index) => (
                  <option key={index} value={reference.replenishmentId}>
                    {reference.reference}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-lg-1 col-4 d-flex align-items-end flex-column">
          <div>
            {!invalidNumber && !forceInvalidNumber ? (
              <button
                className="btn btn-primary verticalAlign"
                onClick={handleBulkUpdate}
              >
                Bijwerken
              </button>
            ) : (
              <button className="btn btn-primary" disabled>
                Bijwerken
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row rowTitles hideOnMobile">
        <div className="col-lg-2"></div>
        <div className="col-lg-3">
          <span className="rowDescription">Product</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Voorgemeld</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Ontvangen</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Onderhanden</span>
        </div>
        <div className="col-lg-1">
          <span className="rowDescription">Op voorraad</span>
        </div>
        <div className="col-lg-3">
          <span className="rowDescription">Voorraad Wijziging</span>
        </div>
      </div>
      <div className="row">
        {!products && (
          <div className="">
            <div class="text-center pt-5">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        )}
        {products &&
          !specificProducts &&
          products
            .filter((product) => product.onHold === false)
            .map((product) => (
              <StockManagementCard
                product={product}
                key={product.offerId}
                handleStockChange={handleStockChange}
              />
            ))}
        {products &&
          !specificProducts &&
          products
            .filter((product) => product.onHold === true)
            .map((product) => (
              <div>
                <h2>On Hold</h2>
                <StockManagementCard
                  product={product}
                  key={product.offerId}
                  handleStockChange={handleStockChange}
                />
              </div>
            ))}
        {specificProducts &&
          specificProducts.map((product) => (
            <StockManagementCard
              product={product}
              key={product.offerId}
              handleStockChange={handleStockChange}
              newArray={newArray}
            />
          ))}
      </div>
    </div>
  );
}

export default StockManagement;
