import { useToast } from '../../context/ToastContext';
import hostNameServer from '../../configHostname';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';

export function useCreateDatabaseDocument() {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const showToast = useToast();

  const createDatabaseDocument = async (data) => {
    const collection = data.collection;

    try {
      const response = await fetch(`${hostNameServer}/api/database/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          data: data.data,
          collection,
        }),
      });

      const json = await response.json();

      if (response.ok) {
        showToast('Nieuwe zending aangemaakt');
        return json;
      }

      if (response.statusText === 'Unauthorized') {
        logout();
      }
    } catch (e) {
      showToast('Oops. Er ging iets mis!');
    }
  };

  return createDatabaseDocument;
}
