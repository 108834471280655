import { React, useState, useEffect } from 'react';
import ProductNavBar from '../components/ProductNav';
import { useCreateDatabaseDocument } from '../controllers/databaseControllers/createDocument';
import { useGetDatabaseDocument } from '../controllers/databaseControllers/getDocument';
import { databaseDocumentTypes } from '../Utils/Types/databaseDocumentTypes';
import { useRefresh } from '../hooks/useRefresh';

import TraceCodeCard from '../components/TraceCodeCard';

function TraceCodes() {
  useRefresh();
  const createDocument = useCreateDatabaseDocument();
  const getDocument = useGetDatabaseDocument();

  const [formData, setFormData] = useState({
    title: '',
    link: '',
    startDate: '',
    endDate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const options = {
      data: formData,
      collection: 'TraceCode',
    };

    const newDocument = await createDocument(options);

    setTracecodes([...tracecodes, newDocument]);

    setFormData({
      title: '',
      link: '',
      products: '',
      startDate: '',
      endDate: '',
      tracecode: '',
    });

    setAddTraceCode(false);
  };

  const [addTraceCode, setAddTraceCode] = useState(false);

  function handleAddClick() {
    setAddTraceCode(!addTraceCode);
  }

  const [tracecodes, setTracecodes] = useState();

  useEffect(() => {
    getAllTraceCodes();
  }, []);

  async function getAllTraceCodes() {
    const tracedCodes = await getDocument(databaseDocumentTypes.tracecodes);

    setTracecodes(tracedCodes);
  }

  //detele product row
  function handleDeleteRow(deletedRow) {
    setTracecodes(tracecodes.filter((item) => item._id !== deletedRow._id));
  }

  //handle received
  function handleReceivedRow(updatedProduct) {
    console.log(updatedProduct);
    console.log(tracecodes);
    const updatedTracecodes = tracecodes.map((product) => (product._id === updatedProduct._id ? updatedProduct : product));
    setTracecodes(updatedTracecodes);
  }

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <ProductNavBar activeTab={'tracecodes'} />
        </div>
        <div className="col-4 d-flex flex-row justify-content-end">
          <div>
            <button className={addTraceCode ? 'btn btn-secondary' : 'btn btn-primary'} onClick={handleAddClick}>
              {addTraceCode ? 'Annuleren' : 'Toevoegen'}
            </button>
          </div>
        </div>
      </div>

      {addTraceCode && (
        <form onSubmit={handleSubmit}>
          <div class="row mb-3">
            <div class="col-lg-2 col-12">
              <input type="text" class="form-control mb-2" placeholder="Titel" name="title" value={FormDataEvent.title} onChange={handleInputChange} />
            </div>
            <div class="col-lg-2 col-12">
              <input
                type="text"
                class="form-control mb-2"
                placeholder="tracecode"
                name="tracecode"
                value={FormDataEvent.tracecode}
                onChange={handleInputChange}
              />
            </div>
            <div class="col-lg-2 col-12">
              <input type="text" class="form-control mb-2" placeholder="Link" name="link" value={FormDataEvent.link} onChange={handleInputChange} />
            </div>
            <div class="col-lg-3 col-12">
              <input
                type="text"
                class="form-control mb-2"
                placeholder="Producten"
                name="products"
                value={FormDataEvent.products}
                onChange={handleInputChange}
              />
            </div>
            <div class="col-lg-1 col-12">
              <input
                type="date"
                class="form-control mb-2"
                placeholder="Begin datum"
                name="startDate"
                value={FormDataEvent.startDate}
                onChange={handleInputChange}
              />
            </div>
            <div class="col-lg-1 col-12">
              <input type="date" class="form-control mb-2" placeholder="Eind datum" name="endDate" value={FormDataEvent.endDate} onChange={handleInputChange} />
            </div>
            <div class="col-lg-1 col-12 d-flex justify-content-end">
              <div>
                <button className="btn btn-primary">Opslaan</button>
              </div>
            </div>
          </div>
        </form>
      )}
      <div className="row">
        <h1 className="py-3">Onderweg</h1>
      </div>

      {tracecodes && tracecodes.find((item) => item.received === false) ? (
        tracecodes
          .filter((item) => item.received === false)
          .map((product) => <TraceCodeCard product={product} key={product._id} handleDeleteRow={handleDeleteRow} handleReceivedRow={handleReceivedRow} />)
      ) : (
        <p className="text-center">Geen trace en traces codes beschikbaar</p>
      )}

      <h1 className="py-3">Ontvangen</h1>

      {tracecodes && tracecodes.find((item) => item.received === true) ? (
        tracecodes
          .filter((item) => item.received === true)
          .map((product) => <TraceCodeCard product={product} key={product._id} handleDeleteRow={handleDeleteRow} handleReceivedRow={handleReceivedRow} />)
      ) : (
        <p className="text-center">Geen trace en traces codes beschikbaar</p>
      )}
    </div>
  );
}

export default TraceCodes;
