import { React, useState } from 'react'
import { useForgetPassword } from "../hooks/useForgotPassword"


function ForgotPassword() {

    const [email, setEmail] = useState("")

    const { forgottenPassword, error, succeed, isLoading } = useForgetPassword();

    const handleSubmit = async (e) => {
        e.preventDefault()

        await forgottenPassword(email)
    }

    return (
        <form className='login' onSubmit={handleSubmit}>
            <h3>Wachtwoord vergeten</h3>
            <label>Email:</label>
            <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />

            <button disabled={isLoading}>Stuur link om wachtwoord te resetten</button>
            {error && <div className='error'>{error}</div>}
            {succeed && <div className='error'>Er is een mail gestuurd met een link om je wachtwoord te herstellen</div>}
        </form >
    )
}

export default ForgotPassword; 